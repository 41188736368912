import React, { FC, useEffect, useState, useContext, Fragment, useMemo } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Portal } from 'react-portal';

// Components
import { DeviceTileSmall, LoadingSpinner } from 'components';
import BlockItem from 'page/Editor/configuration/BlockItem';
import { ContextMenu } from 'page/Editor/Menu/Shared';

// Context
import { EditorContext, LangContext } from 'context';

// Types
import { Category, Device, ErrorAlert } from 'types';

// Utils
import { isFryer, post } from 'utils';

// Styles
import { colors } from 'styles/theme';
import './style.scss';
import { isSubstructureClashing } from 'utils/isSubstructureClashing';
import { EquipmentHelper } from 'page/Editor/configuration/Equipment';
import BlockGroup from 'page/Editor/configuration/BlockGroup';
import { addHygiene } from 'utils/addDevice';
import { getNOLWingedDoorDevice } from 'utils/getNOLWingedDoorDevice';

// ===================================================================
interface Props {
  category: Category;
  detailedView?: boolean;
  isLast?: boolean;
  onSelect?: () => void;
  selectDevice?: (d: Device) => void;
}

// ===================================================================
export const CategoryMenu: FC<Props> = ({ category, detailedView = false, isLast = false, onSelect, selectDevice }) => {
  const { menuView, scene, seriesFilter, selectedModular, selectedModularNOL, selectedMasterline, selectedMarineMeister, setErrorAlert, setSeriesFilter } =
    useContext(EditorContext);
  const block = selectedMasterline || selectedModular || selectedModularNOL || selectedMarineMeister;
  const { langId } = useContext(LangContext);

  const [devices, setDevices] = useState<Device[]>([]);
  const [filteredDevices, setFilteredDivices] = useState<Device[]>([]);
  const [loading, setLoading] = useState(false);
  const [draggingInScene, setDraggingInScene] = useState<boolean>(false);

  const voltage = useMemo(() => (menuView === 'marineMeister' ? selectedMarineMeister?.getVoltage() : 0), [menuView, selectedMarineMeister]);

  const key = `devices-category-${category ? category.id : ''}${menuView}-${voltage}`;

  const refreshList = () => {
    setFilteredDivices([...filteredDevices]);
  };

  useEffect(() => {
    // Workaround
    if (seriesFilter && !(seriesFilter === '700' || seriesFilter === '850')) {
      setSeriesFilter('all');
    }
    setLoading(true);
    const fetchDevices = async () => {
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/editor/menu/devices`, {
        data: {
          basicOnly: true,
          type: menuView,
          category: {
            id: category.id
          },
          voltage: menuView === 'marineMeister' ? voltage : undefined
        }
      });
      if (data) {
        setDevices(data);
        sessionStorage.setItem(key, JSON.stringify(data));
      }
      if (error) {
        console.log(error);
      }
    };
    const cachedDevices = sessionStorage.getItem(key);
    if (detailedView) {
      if (cachedDevices) {
        setDevices(JSON.parse(cachedDevices));
      } else {
        fetchDevices();
      }
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    if (seriesFilter && seriesFilter !== 'all') {
      setFilteredDivices(
        devices.filter(d => {
          return (
            d.model.depths.includes(parseInt(seriesFilter)) &&
            (menuView !== 'masterline' ||
              (menuView === 'masterline' &&
                selectedMasterline &&
                ((!d.style.includes('exclude:SingleFree') && selectedMasterline.getType() === 'Single' && selectedMasterline.getSingleType() === 'Free') ||
                  selectedMasterline.getType() !== 'Single' ||
                  selectedMasterline.getSingleType() !== 'Free')))
          );
        })
      );
    } else {
      setFilteredDivices(devices);
    }
  }, [devices, seriesFilter, selectedMasterline]);

  if (detailedView) {
    return (
      <Fragment>
        <ContextMenu border noMargin mode="back" onClick={onSelect}>
          <div className="ml-1 flex align-center">
            <div className="Functional-Menu-Subsection-Title">
              <span>{category ? category.translations[langId].name : ''}</span>
            </div>
          </div>
        </ContextMenu>
        <div>
          {loading && <LoadingSpinner color="blue" />}
          <DragDropContext
            onDragUpdate={initial => {
              if (scene && initial.destination && initial.destination.droppableId.includes('Droppable-Devices-Scene') && !draggingInScene) {
                scene.onDragOver(
                  filteredDevices[initial.source.index],
                  filteredDevices[initial.source.index].model.modular
                    ? selectedModular
                    : filteredDevices[initial.source.index].model.modularNOL
                    ? selectedModularNOL
                    : selectedMasterline
                );
                setDraggingInScene(true);
                document.getElementById(`${initial.draggableId}-Div`).style.display = 'none';
              } else if (scene && (!initial.destination || initial.destination.droppableId === 'Droppable-Devices') && draggingInScene) {
                scene.onDragOut();
                setDraggingInScene(false);
                document.getElementById(`${initial.draggableId}-Div`).style.display = 'block';
              }
            }}
            onDragEnd={async () => {
              console.log('ende');
              setDraggingInScene(false);
              // Arbeitstisch
              const selection = scene.getSelected();
              if (block.isAutoHygiene() && (selection instanceof BlockItem || selection instanceof BlockGroup) && EquipmentHelper.canHaveHygiene(selection)) {
                addHygiene(selection, block.getRowTop().getDepth());
              }
              if (selection && selection instanceof BlockItem) {
                const errorAlerts: ErrorAlert[] = [];
                if (selection.getDeviceObject() && selection.getDeviceObject().category && isFryer(category)) {
                  errorAlerts.push('fryer');
                }
                if (selection.getDeviceObject() && selection.getDeviceObject().dependency.coverEnlargement && selection.getDeviceObject().model.masterline) {
                  errorAlerts.push('coverEnlargement');
                }
                if (errorAlerts.length > 0) {
                  setErrorAlert(errorAlerts);
                }
              }
              if (
                block &&
                menuView === 'modularNOL' &&
                (selection instanceof BlockItem || selection instanceof BlockGroup) &&
                EquipmentHelper.canHaveWingedDoor(selection)
              ) {
                const wingedDoorDevice = await getNOLWingedDoorDevice(selection);
                EquipmentHelper.setWingedDoor(selection, wingedDoorDevice);
              }
              // ==================================================
            }}
          >
            {/* Menu */}
            <Droppable droppableId="Droppable-Devices" ignoreContainerClipping isDropDisabled>
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {filteredDevices &&
                    filteredDevices.length > 0 &&
                    filteredDevices.map((device: Device, index: number) => {
                      const itemAddable = device.model.modular
                        ? selectedModular
                          ? selectedModular.canItemBeDropped(device, selectedModular.getRowTop()) ||
                            selectedModular.canItemBeDropped(device, selectedModular.getRowBottom())
                          : false
                        : device.model.modularNOL
                        ? selectedModularNOL
                          ? selectedModularNOL.canItemBeDropped(device, selectedModularNOL.getRowTop()) ||
                            selectedModularNOL.canItemBeDropped(device, selectedModularNOL.getRowBottom())
                          : false
                        : device.model.masterline && selectedMasterline
                        ? (!isSubstructureClashing(device, selectedMasterline) &&
                            selectedMasterline.canItemBeDropped(device, selectedMasterline.getRowTop())) ||
                          selectedMasterline.canItemBeDropped(device, selectedMasterline.getRowBottom())
                        : selectedMarineMeister
                        ? selectedMarineMeister.canItemBeDropped(device, selectedMarineMeister.getRowTop()) ||
                          selectedMarineMeister.canItemBeDropped(device, selectedMarineMeister.getRowBottom())
                        : false;
                      return (
                        <Draggable
                          key={`Draggable-Device-${device.id}`}
                          isDragDisabled={!itemAddable}
                          draggableId={`Draggable-Device-${device.id}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div>
                              <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                <DeviceTileSmall addable={itemAddable} device={device} selectDevice={selectDevice} refresh={refreshList} />
                              </div>
                              {snapshot.isDragging && (
                                <div id={`Draggable-Device-${device.id}-Div`}>
                                  <DeviceTileSmall addable={itemAddable} device={device} selectDevice={selectDevice} refresh={refreshList} />
                                </div>
                              )}
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            {/* Scene */}
            <Portal>
              <Droppable droppableId="Droppable-Devices-Scene" ignoreContainerClipping>
                {(provided, snapshot) => (
                  <div
                    className="Droppable-Devices-Scene"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ border: '5px solid ' + (snapshot.isDraggingOver ? colors['lightBlue'] : 'transparent') }}
                  >
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Portal>
          </DragDropContext>
        </div>
      </Fragment>
    );
  }

  return (
    <div className={!isLast ? 'Functional-Menu-Subsection' : ''} onClick={onSelect}>
      <div className="Functional-Menu-Subsection-Title">
        {category && category.translations && langId && category.translations[langId]
          ? `${category.translations[langId].name} (${category.deviceCount[seriesFilter === '700' ? '_700' : seriesFilter === '850' ? '_850' : 'total']})`
          : `${category.translations['O43r1'].name} (${category.deviceCount[seriesFilter === '700' ? '_700' : seriesFilter === '850' ? '_850' : 'total']})`}
      </div>
    </div>
  );
};
