const LHeader = {
  showPrices: {
    fr: 'Afficher les prix',
    de: 'Preise einblenden',
    en: 'Show Prices',
    cz: 'Zobrazit ceny'
  },
  hidePrices: {
    fr: 'Masquer les prix',
    de: 'Preise ausblenden',
    en: 'Hide Prices',
    cz: 'Skrýt ceny'
  },
  yourConfig: {
    fr: 'Votre configuration',
    de: 'Ihre Konfiguration',
    en: 'Your Configuration',
    cz: 'Vaše konfigurace'
  },
  sketch: {
    fr: 'Dessin en 2D',
    de: 'Planungsskizze',
    en: '2D drawing',
    cz: 'Plánovací nákres'
  },
  addSketch: {
    fr: 'Add 2D drawing',
    de: 'Planungsskizze hinzufügen',
    en: 'Add 2D drawing',
    cz: 'Přidat plánovací nákres'
  },
  downloadSketch: {
    fr: 'Télécharger le fichier DXF',
    de: 'DXF-Datei herunterladen',
    en: 'Download DXF-file',
    cz: 'Stáhnout soubor DXF'
  },
  file: {
    fr: 'Fichier',
    de: 'Datei',
    en: 'File',
    cz: 'Soubor'
  },
  offering: {
    fr: 'Caractéristiques ',
    de: 'Beschreibung',
    en: 'Specifications',
    cz: 'Popis'
  },
  offeringWithPrice: {
    fr: 'Caractéristiques et prix',
    de: 'Beschreibung inkl. Preis',
    en: 'Specifications with price',
    cz: 'Popis vč. ceny'
  },
  makeConfigPublic: {
    fr: 'Publier la configuration',
    de: 'Konfiguration veröffentlichen',
    en: 'Publish Configuration',
    cz: 'Zveřejnit konfiguraci'
  },
  send: {
    fr: 'Envoyer',
    de: 'Senden',
    en: 'Send',
    cz: 'Odeslat'
  },
  savePDF: {
    fr: 'Enregistrer PDF',
    de: 'PDF Speichern',
    en: 'Save PDF',
    cz: 'Uložit PDF'
  },
  share: {
    fr: 'Partager',
    de: 'Teilen',
    en: 'Share',
    cz: 'Sdílet'
  },
  download: {
    fr: 'Télécharger',
    de: 'Downloaden',
    en: 'Download',
    cz: 'Stáhnout'
  },
  warning: {
    fr: 'Attention',
    de: 'Hinweis',
    en: 'Caution',
    cz: 'Upozornění'
  },
  notificationMessage: {
    fr: 'Seule la configuration publique peut être publiée via le code.',
    de: 'Nur öffentliche Konfigurationen können über den Code weitergegeben werden.',
    en: 'Only public Configuration can be published via Code.',
    cz: 'Prostřednictvím kódu lze předávat pouze veřejné konfigurace.'
  },
  yourMessage: {
    fr: 'Votre message',
    de: 'Ihre Nachricht',
    en: 'Your Message',
    cz: 'Vaše zpráva'
  },
  contactFromMKN: {
    fr: 'Je souhaite être contacté par MKN',
    de: 'Ich wünsche eine Kontaktaufnahme durch MKN',
    en: 'I would like to be contacted by MKN',
    cz: 'Prosím o kontaktování společností MKN'
  },
  emails: {
    fr: 'Email Address/es',
    de: 'E-Mail Adresse/n',
    en: 'Email Address/es',
    cz: 'E-mailová/é adresa/y'
  },
  amount: {
    fr: 'Montant',
    de: 'Anzahl',
    en: 'Amount',
    cz: 'Počet'
  },
  mknCode: {
    fr: 'Code MKN',
    de: 'MKN-Nummer',
    en: 'MKN-Code',
    cz: 'Číslo MKN'
  },
  article: {
    fr: 'Objet',
    de: 'Artikel',
    en: 'Item',
    cz: 'Výrobek'
  },
  price: {
    fr: 'Prix',
    de: 'Preis',
    en: 'Price',
    cz: 'Cena'
  },
  page: {
    fr: 'Page',
    de: 'Seite',
    en: 'Page',
    cz: 'Strana'
  },
  pageOf: {
    fr: 'de',
    de: 'von',
    en: 'of',
    cz: 'z'
  },
  pdfIntro: {
    fr: "Madame, Monsieur, Nous vous remercions de votre demande et de l'intérêt que vous portez à la MKN Professional Cooking Technology !\n\nPar la présente, nous vous soumettons une proposition d'appareil pour votre projet.\n\nL'équipe commerciale de MKN se fera un plaisir de vous soumettre une offre officielle, basée sur nos conditions générales de vente, de livraison, de montage et de paiement.",
    de: 'Sehr geehrte Damen und Herren, vielen Dank für Ihre Anfrage und das damit bekundete Interesse an der MKN-Profikochtechnik!\n\nHiermit überreichen wir Ihnen einen unverbindlichen Gerätevorschlag für Ihr Projekt.\n\nEin offizielles Angebot, welchem auch unsere allgemeinen Verkaufs-, Liefer-, Montage- und Zahlungsbedingungen zugrunde liegen, stellt Ihnen das MKN-Vertriebsteam gern zeitnah zur Verfügung.',
    en: 'Dear Sir or Madam, Thank you for your inquiry and the interest you have shown in MKN Professional Cooking Technology!\n\nWe hereby present you with a non-binding device proposal for your project.\n\nThe MKN sales team will be happy to provide you with an official offer, which is also based on our general sales, delivery, assembly and payment conditions.',
    cz: 'Vážené dámy a vážení pánové, děkujeme za vaši poptávku a projevený zájem o profesionální varnou techniku MKN!\n\nTímto vám zasíláme nezávazný návrh přístrojů pro váš projekt.\n\nOficiální nabídku, která vychází také z našich všeobecných prodejních, dodacích, montážních a platebních podmínek, vám rád brzy předloží prodejní tým společnosti MKN.'
  },
  calcError: {
    fr: "L'aperçu des prix n'a pas pu être mis à jour",
    de: 'Preisübersicht konnte nicht aktualisiert werden.',
    en: 'Price overview could not be updated',
    cz: 'Přehled cen nemohl být aktualizován.'
  },
  calcAgain: {
    fr: 'Calculer les prix',
    de: 'Neu berechnen',
    en: 'Calculate prices',
    cz: 'Znovu spočítat'
  },
  empty: {
    fr: 'Vide',
    de: 'Leer',
    en: 'Empty',
    cz: 'Prázdný'
  },
  type: {
    fr: 'Type',
    de: 'Typ',
    en: 'Type',
    cz: 'Typ'
  },
  marineMeister: {
    fr: 'version marine',
    de: 'MarineMeister',
    en: 'MarineMeister',
    cz: 'MarineMeister'
  },
  offerHint: {
    fr: 'Offre non contraignante',
    de: 'Kein verbindliches Angebot',
    en: 'Non-binding offer',
    cz: 'Nezávazná nabídka'
  },
  installationWallHint: {
    de: 'Bitte beachten: Die Installationswand ist nicht im Preis enthalten.',
    en: 'Please note: The installation wall is not included in the price.',
    fr: "Veuillez noter : le mur d'installation n'est pas inclus dans le prix.",
    cz: 'Poznámka: Instalační stěna není zahrnuta v ceně.'
  }
};

export default LHeader;
