import { FC, Fragment, useContext, useRef, useState } from 'react';

// Components
import { Button, Selectbox } from 'atoms';
import { ContextMenu, EquipmentSwitch } from 'page/Editor/Menu/Shared';
import BlockBoard from 'page/Editor/configuration/BlockBoard';
import BlockGroup from 'page/Editor/configuration/BlockGroup';
import BlockItem from 'page/Editor/configuration/BlockItem';
import { EquipmentHelper } from 'page/Editor/configuration/Equipment';

// Context
import { EditorContext, LangContext } from 'context';

// Language
import Localizations from 'languages';

// Styles:
import { colors } from 'styles/theme';

// Types
import { Device, Subtype } from 'types';

// Utilities
import { LoadingSpinner } from 'components';
import { useSelectionChange, useUpdateAvailableOvens, useUpdateDisables, useUpdateOvens, useUpdateContainsMerges, useUpdateSubstructureMode } from './hooks';
import { initializeContainsMerges, isSubtype } from 'utils';

const REMOTE_REFRIDGERATED_DEVICECODES = ['10029909', '10029907'];

export type UsedMasterlineSubtypeDevices = {
  WingedDoor: boolean;
  Hygiene: boolean;
  AngularRail: boolean;
  LowerDrawer: boolean;
  HeatingCabinet: boolean;
  HeatingDrawer: boolean;
  EmptyingSystem: boolean;
  Cooling: boolean;
  OpenSubstructure: boolean;
  WasteDrawer: boolean;
  GNFrame: boolean;
  GNFrameSecond: boolean;
  WarmingDrawer: boolean;
  WarmingDevice: boolean;
  IntermediatePlate: boolean;
  baseCoverLeft: boolean;
  baseCoverRight: boolean;
  readyXPressLeft: boolean;
  readyXPressRight: boolean;
  spaceClean: boolean;
};

const getUsedBaseCoverDevices = (selection: BlockItem, availableDevices: Device[]): Device[] | null => {
  const style = selection?.getDeviceObject()?.style;
  const filteredDevices = availableDevices.filter(d => isSubtype(d.subtype, [Subtype.BaseCover]));

  if (style) {
    // Team
    const params = new URLSearchParams(style);
    if (style.includes('-')) {
      const S = params.get('S');
      const leftIndex = S.split('-')[0];
      const rightIndex = S.split('-')[1][0];
      const leftStyle = `S=${leftIndex}TL`;
      const rightStyle = `S=${rightIndex}TR`;

      const leftDevice = filteredDevices.find(d => d.style === leftStyle);
      const rightDevice = filteredDevices.find(d => d.style === rightStyle);

      return [leftDevice, rightDevice];
    } else {
      const leftStyle = params.get('S');
      const device = filteredDevices.find(d => d.style === `S=${leftStyle[0]}`);
      if (device) return [device];
      return [];
    }
  }
  return [];
};

// ===================================================================
interface Props {}

// ===================================================================
export const MasterlineSubstructure: FC<Props> = () => {
  const { lang, langId } = useContext(LangContext);

  const { scene, selectedMasterline, selection, setErrorAlert, setMasterlineView, setSelection } = useContext(EditorContext);
  const [mergeMode, setMergeMode] = useState(false);
  const somethingSelected = useRef(false);

  const [disabledSwitches, setDisabledSwitches] = useState<String[]>([]);
  const [ovens, setOvens] = useState<Device[]>([]);
  const [selectedOven, setSelectedOven] = useState(Localizations['none'][lang]);

  const [usedDevices, setUsedDevices] = useState<UsedMasterlineSubtypeDevices>({
    WingedDoor: false,
    Hygiene: false,
    AngularRail: false,
    LowerDrawer: false,
    HeatingCabinet: false,
    HeatingDrawer: false,
    EmptyingSystem: false,
    Cooling: false,
    OpenSubstructure: false,
    WasteDrawer: false,
    GNFrame: false,
    GNFrameSecond: false,
    WarmingDrawer: false,
    WarmingDevice: false,
    IntermediatePlate: false,
    baseCoverLeft: false,
    baseCoverRight: false,
    readyXPressLeft: false,
    readyXPressRight: false,
    spaceClean: false
  });

  useUpdateSubstructureMode({ somethingSelected });

  const { availableDevices, availableSubtypes, isLoading, isTeamDevice, selectionType } = useSelectionChange({
    setMergeMode,
    setSelectedOven,
    setUsedDevices,
    somethingSelected
  });
  useUpdateAvailableOvens({ availableDevices, setOvens });

  useUpdateContainsMerges();

  useUpdateOvens({ ovens, selectedOven, setSelectedOven, somethingSelected });

  useUpdateDisables({ selectedOven, setDisabledSwitches, usedDevices });

  const widthSubstructure = selection instanceof BlockItem || selection instanceof BlockGroup ? selection.getWidthSubstructure() : 0;

  const isUpperStructureOnly = selectedMasterline.getUpperstrcutureOnly();

  return (
    <Fragment>
      <ContextMenu
        mode="back"
        onClick={() => {
          setMasterlineView('home');
          setSelection(null);
          scene.setSelected(null);
        }}
      />
      {isLoading ? (
        <LoadingSpinner color="medium" />
      ) : isUpperStructureOnly ? (
        <div className="mt-4 text-sm bold">{Localizations['noSubstructureActive'][lang]}</div>
      ) : (
        <>
          <div
            className="mb-1"
            style={{
              borderBottom: '1px solid ' + colors['greyText'],
              paddingBottom: '1.5rem'
            }}
          >
            <Button
              btnType={selection ? 'third' : 'second'}
              disabled={!selection || selection instanceof BlockBoard || (selection instanceof BlockItem && !selection.canItemBeMerged())}
              padding=".5rem"
              fontSize=".75rem"
              onClick={() => {
                if (selection) {
                  if (selection instanceof BlockGroup) {
                    selection.disband();
                    scene.setSelected(null);
                    setSelection(null);
                  }
                  scene.setMergeMode(!mergeMode);
                  setMergeMode(!mergeMode);
                  initializeContainsMerges(selectedMasterline);
                }
              }}
            >
              {Localizations[(selection && mergeMode) || (selection && selection instanceof BlockGroup) ? 'removeConnection' : 'connectSubstructure'][lang]}
            </Button>
          </div>
          {selectionType && !(selectionType === 'BlockItem' && mergeMode) && (
            <div
              className="bold mb-1 text-xs uppercase"
              style={{
                color: colors['greyText']
              }}
            >
              {Localizations['specialEquipment'][lang]}
            </div>
          )}

          {!selection && <div className="mt-4 text-sm bold">{Localizations['pickADevice'][lang]}</div>}

          {selection && ((selection instanceof BlockItem && !mergeMode) || selection instanceof BlockGroup) && (
            <div className="Custom-Scroll" style={{ maxHeight: 'calc(100% - 160px)' }}>
              {availableSubtypes.includes(Subtype.WingedDoor) && (
                <EquipmentSwitch
                  disabled={disabledSwitches.includes('WingedDoor')}
                  description={
                    availableDevices.find(d => isSubtype(d.subtype, ['WingedDoor']))
                      ? availableDevices.find(d => isSubtype(d.subtype, ['WingedDoor'])).translations[langId].name
                      : ''
                  }
                  status={usedDevices.WingedDoor}
                  label={Localizations['wingedDoor'][lang]}
                  toggleStatus={() => {
                    if (!usedDevices.WingedDoor) {
                      EquipmentHelper.setWingedDoor(
                        selection,
                        availableDevices.find(d => isSubtype(d.subtype, ['WingedDoor']))
                      );
                    } else {
                      EquipmentHelper.setWingedDoor(selection, null);
                    }
                    setUsedDevices({ ...usedDevices, WingedDoor: !usedDevices.WingedDoor });
                  }}
                  type="first"
                />
              )}
              {availableSubtypes.includes(Subtype.Hygiene) && (
                <EquipmentSwitch
                  disabled={disabledSwitches.includes('Hygiene')}
                  description={
                    availableDevices.find(d => isSubtype(d.subtype, ['Hygiene']))
                      ? availableDevices.find(d => isSubtype(d.subtype, ['Hygiene'])).translations[langId].name
                      : ''
                  }
                  status={EquipmentHelper.getHygiene(selection) ? true : false}
                  label={Localizations['hygiene'][lang]}
                  toggleStatus={() => {
                    if (!usedDevices.Hygiene) {
                      EquipmentHelper.setHygiene(
                        selection,
                        availableDevices.find(d => isSubtype(d.subtype, ['Hygiene']))
                      );
                    } else {
                      EquipmentHelper.setHygiene(selection, null);
                    }
                    setUsedDevices({ ...usedDevices, Hygiene: !usedDevices.Hygiene });
                  }}
                  type="first"
                />
              )}
              {availableSubtypes.includes(Subtype.AngularRail) && (
                <EquipmentSwitch
                  disabled={disabledSwitches.includes('AngularRail')}
                  description={
                    availableDevices.find(d => isSubtype(d.subtype, ['AngularRail']))
                      ? availableDevices.find(d => isSubtype(d.subtype, ['AngularRail'])).translations[langId].name
                      : ''
                  }
                  status={usedDevices.AngularRail}
                  label={Localizations['angularRail'][lang]}
                  toggleStatus={() => {
                    if (!usedDevices.AngularRail) {
                      EquipmentHelper.setAngularRail(
                        selection,
                        availableDevices.find(d => isSubtype(d.subtype, ['AngularRail']))
                      );
                    } else {
                      EquipmentHelper.setAngularRail(selection, null);
                    }
                    setUsedDevices({ ...usedDevices, AngularRail: !usedDevices.AngularRail });
                  }}
                  type="first"
                />
              )}
              {availableSubtypes.includes(Subtype.LowerDrawer) && (
                <EquipmentSwitch
                  disabled={disabledSwitches.includes('LowerDrawer')}
                  description={
                    availableDevices.find(d => isSubtype(d.subtype, ['LowerDrawer']))
                      ? availableDevices.find(d => isSubtype(d.subtype, ['LowerDrawer'])).translations[langId].name
                      : ''
                  }
                  status={usedDevices.LowerDrawer}
                  label={Localizations['lowerDrawer'][lang]}
                  toggleStatus={() => {
                    if (!usedDevices.LowerDrawer) {
                      EquipmentHelper.setLowerDrawer(
                        selection,
                        availableDevices.find(d => isSubtype(d.subtype, ['LowerDrawer']))
                      );
                    } else {
                      EquipmentHelper.setLowerDrawer(selection, null);
                    }
                    setUsedDevices({ ...usedDevices, LowerDrawer: !usedDevices.LowerDrawer });
                  }}
                  type="first"
                />
              )}
              {availableSubtypes.includes(Subtype.HeatingCabinet) && (
                <EquipmentSwitch
                  disabled={disabledSwitches.includes('HeatingCabinet')}
                  description={
                    availableDevices.find(d => isSubtype(d.subtype, ['HeatingCabinet']))
                      ? availableDevices.find(d => isSubtype(d.subtype, ['HeatingCabinet'])).translations[langId].name
                      : ''
                  }
                  status={usedDevices.HeatingCabinet}
                  label={Localizations['heatingCabinet'][lang]}
                  toggleStatus={() => {
                    if (!usedDevices.HeatingCabinet) {
                      EquipmentHelper.setHeatingCabinet(
                        selection,
                        availableDevices.find(d => isSubtype(d.subtype, ['HeatingCabinet']))
                      );
                    } else {
                      EquipmentHelper.setHeatingCabinet(selection, null);
                    }
                    setUsedDevices({ ...usedDevices, HeatingCabinet: !usedDevices.HeatingCabinet });
                  }}
                  type="first"
                />
              )}
              {availableSubtypes.includes(Subtype.EmptyingSystem) && (
                <EquipmentSwitch
                  disabled={disabledSwitches.includes('EmptyingSystem')}
                  description={
                    availableDevices.find(d => isSubtype(d.subtype, ['EmptyingSystem']))
                      ? availableDevices.find(d => isSubtype(d.subtype, ['EmptyingSystem'])).translations[langId].name
                      : ''
                  }
                  status={usedDevices.EmptyingSystem}
                  label={Localizations['emptyingSystem'][lang]}
                  toggleStatus={() => {
                    if (!usedDevices.EmptyingSystem) {
                      EquipmentHelper.setEmptyingSystem(
                        selection,
                        availableDevices.find(d => isSubtype(d.subtype, ['EmptyingSystem']))
                      );
                    } else {
                      EquipmentHelper.setEmptyingSystem(selection, null);
                    }
                    setUsedDevices({ ...usedDevices, EmptyingSystem: !usedDevices.EmptyingSystem });
                  }}
                  type="first"
                />
              )}
              {availableSubtypes.includes(Subtype.HeatingDrawer) && (
                <EquipmentSwitch
                  status={usedDevices.HeatingDrawer}
                  disabled={disabledSwitches.includes('HeatingDrawer')}
                  description={
                    availableDevices.find(d => isSubtype(d.subtype, ['HeatingDrawer']))
                      ? availableDevices.find(d => isSubtype(d.subtype, ['HeatingDrawer'])).translations[langId].name
                      : ''
                  }
                  label={Localizations['heatingDrawer'][lang]}
                  toggleStatus={() => {
                    if (!usedDevices.HeatingDrawer) {
                      EquipmentHelper.setHeatingDrawer(
                        selection,
                        availableDevices.find(d => isSubtype(d.subtype, ['HeatingDrawer']))
                      );
                    } else {
                      EquipmentHelper.setHeatingDrawer(selection, null);
                    }
                    setUsedDevices({ ...usedDevices, HeatingDrawer: !usedDevices.HeatingDrawer });
                  }}
                  type="first"
                />
              )}
              {availableSubtypes.includes(Subtype.Cooling) && (
                <EquipmentSwitch
                  disabled={disabledSwitches.includes('Cooling')}
                  description={
                    availableDevices.find(d => isSubtype(d.subtype, ['Cooling']))
                      ? availableDevices.find(d => isSubtype(d.subtype, ['Cooling'])).translations[langId].name
                      : ''
                  }
                  status={usedDevices.Cooling}
                  label={Localizations['cooling'][lang]}
                  toggleStatus={() => {
                    if (!usedDevices.Cooling) {
                      const coolingDevice = availableDevices.find(d => isSubtype(d.subtype, ['Cooling']));
                      EquipmentHelper.setCooling(selection, coolingDevice);
                      console.log(coolingDevice);
                      if (REMOTE_REFRIDGERATED_DEVICECODES.includes(coolingDevice.code)) setErrorAlert(['cooling']);
                    } else {
                      EquipmentHelper.setCooling(selection, null);
                    }
                    setUsedDevices({ ...usedDevices, Cooling: !usedDevices.Cooling });
                  }}
                  type="first"
                />
              )}
              {availableSubtypes.includes(Subtype.OpenSubstructure) && (
                <EquipmentSwitch
                  disabled={disabledSwitches.includes('OpenSubstructure')}
                  description={
                    availableDevices.find(d => isSubtype(d.subtype, ['OpenSubstructure']))
                      ? availableDevices.find(d => isSubtype(d.subtype, ['OpenSubstructure'])).translations[langId].name
                      : ''
                  }
                  status={usedDevices.OpenSubstructure}
                  label={Localizations['openSubstructure'][lang]}
                  toggleStatus={() => {
                    if (!usedDevices.OpenSubstructure) {
                      EquipmentHelper.setOpenSubstructure(
                        selection,
                        availableDevices.find(d => isSubtype(d.subtype, ['OpenSubstructure']))
                      );
                    } else {
                      EquipmentHelper.setOpenSubstructure(selection, null);
                    }
                    setUsedDevices({ ...usedDevices, OpenSubstructure: !usedDevices.OpenSubstructure });
                  }}
                  type="first"
                />
              )}
              {availableSubtypes.includes(Subtype.WasteDrawer) && (
                <EquipmentSwitch
                  disabled={disabledSwitches.includes('WasteDrawer')}
                  description={
                    availableDevices.find(d => isSubtype(d.subtype, ['WasteDrawer']))
                      ? availableDevices.find(d => isSubtype(d.subtype, ['WasteDrawer'])).translations[langId].name
                      : ''
                  }
                  status={usedDevices.WasteDrawer}
                  label={Localizations['wasteDrawer'][lang]}
                  toggleStatus={() => {
                    if (!usedDevices.WasteDrawer) {
                      EquipmentHelper.setWasteDrawer(
                        selection,
                        availableDevices.find(d => isSubtype(d.subtype, ['WasteDrawer']))
                      );
                    } else {
                      EquipmentHelper.setWasteDrawer(selection, null);
                    }
                    setUsedDevices({ ...usedDevices, WasteDrawer: !usedDevices.WasteDrawer });
                  }}
                  type="first"
                />
              )}
              {availableSubtypes.includes(Subtype.GNFrame) && (
                <EquipmentSwitch
                  disabled={disabledSwitches.includes('GNFrame')}
                  description={
                    availableDevices.find(d => isSubtype(d.subtype, ['GNFrame']))
                      ? availableDevices.find(d => isSubtype(d.subtype, ['GNFrame'])).translations[langId].name
                      : ''
                  }
                  status={usedDevices.GNFrame}
                  label={`${Localizations['gNFrame'][lang]} (1)`}
                  toggleStatus={() => {
                    if (!usedDevices.GNFrame) {
                      EquipmentHelper.setGNFrame(
                        selection,
                        availableDevices.find(d => isSubtype(d.subtype, ['GNFrame']))
                      );
                    } else {
                      EquipmentHelper.setGNFrame(selection, null);
                    }
                    setUsedDevices({ ...usedDevices, GNFrame: !usedDevices.GNFrame });
                  }}
                  type="first"
                />
              )}
              {widthSubstructure >= 800 && availableSubtypes.includes(Subtype.GNFrame) && (
                <EquipmentSwitch
                  disabled={disabledSwitches.includes('GNFrame')}
                  description={
                    availableDevices.find(d => isSubtype(d.subtype, ['GNFrame']))
                      ? availableDevices.find(d => isSubtype(d.subtype, ['GNFrame'])).translations[langId].name
                      : ''
                  }
                  status={usedDevices.GNFrameSecond}
                  label={`${Localizations['gNFrame'][lang]} (2)`}
                  toggleStatus={() => {
                    if (!usedDevices.GNFrameSecond) {
                      EquipmentHelper.setGNFrame(
                        selection,
                        availableDevices.find(d => isSubtype(d.subtype, ['GNFrame'])),
                        1
                      );
                    } else {
                      EquipmentHelper.setGNFrame(selection, null, 1);
                    }
                    setUsedDevices({ ...usedDevices, GNFrameSecond: !usedDevices.GNFrameSecond });
                  }}
                  type="first"
                />
              )}
              {availableSubtypes.includes(Subtype.WarmingDrawer) && (
                <EquipmentSwitch
                  disabled={disabledSwitches.includes('WarmingDrawer')}
                  description={
                    availableDevices.find(d => isSubtype(d.subtype, ['WarmingDrawer']))
                      ? availableDevices.find(d => isSubtype(d.subtype, ['WarmingDrawer'])).translations[langId].name
                      : ''
                  }
                  status={usedDevices.WarmingDrawer}
                  label={Localizations['warmingDrawer'][lang]}
                  toggleStatus={() => {
                    if (!usedDevices.WarmingDrawer) {
                      EquipmentHelper.setWarmingDrawer(
                        selection,
                        availableDevices.find(d => isSubtype(d.subtype, ['WarmingDrawer']))
                      );
                    } else {
                      EquipmentHelper.setWarmingDrawer(selection, null);
                    }
                    setUsedDevices({ ...usedDevices, WarmingDrawer: !usedDevices.WarmingDrawer });
                  }}
                  type="first"
                />
              )}
              {availableSubtypes.includes(Subtype.WarmingDevice) && (
                <EquipmentSwitch
                  disabled={disabledSwitches.includes('WarmingDevice')}
                  description={
                    availableDevices.find(d => isSubtype(d.subtype, ['WarmingDevice']))
                      ? availableDevices.find(d => isSubtype(d.subtype, ['WarmingDevice'])).translations[langId].name
                      : ''
                  }
                  status={usedDevices.WarmingDevice}
                  label={Localizations['warmingDevice'][lang]}
                  toggleStatus={() => {
                    if (!usedDevices.WarmingDevice) {
                      EquipmentHelper.setWarmingDevice(
                        selection,
                        availableDevices.find(d => isSubtype(d.subtype, ['WarmingDevice']))
                      );
                    } else {
                      EquipmentHelper.setWarmingDevice(selection, null);
                    }
                    setUsedDevices({ ...usedDevices, WarmingDevice: !usedDevices.WarmingDevice });
                  }}
                  type="first"
                />
              )}

              {availableSubtypes.includes(Subtype.BaseCover) && (
                <EquipmentSwitch
                  status={usedDevices.baseCoverLeft}
                  toggleStatus={() => {
                    if (usedDevices?.baseCoverLeft) {
                      EquipmentHelper.setBaseCover(selection, null);
                    } else {
                      const devices =
                        selection instanceof BlockItem
                          ? getUsedBaseCoverDevices(selection, availableDevices)
                          : availableDevices.filter(d => isSubtype(d.subtype, ['BaseCover']))[0];
                      EquipmentHelper.setBaseCover(selection, devices[0]);
                    }
                    setUsedDevices({ ...usedDevices, baseCoverLeft: !usedDevices.baseCoverLeft });
                  }}
                  label={`${Localizations['baseCover'][lang]} ${isTeamDevice ? Localizations['left'][lang] : ''}`}
                />
              )}
              {availableSubtypes.includes(Subtype.BaseCover) && isTeamDevice && (
                <EquipmentSwitch
                  status={usedDevices.baseCoverRight}
                  toggleStatus={() => {
                    if (usedDevices?.baseCoverRight) {
                      EquipmentHelper.setBaseCover(selection, null, 1);
                    } else {
                      const devices =
                        selection instanceof BlockItem
                          ? getUsedBaseCoverDevices(selection, availableDevices)
                          : availableDevices.filter(d => isSubtype(d.subtype, ['BaseCover']))[1];
                      EquipmentHelper.setBaseCover(selection, devices[1], 1);
                    }
                    setUsedDevices({ ...usedDevices, baseCoverRight: !usedDevices.baseCoverRight });
                  }}
                  label={`${Localizations['baseCover'][lang]} ${Localizations['right'][lang]}`}
                />
              )}
              {availableSubtypes.includes(Subtype.IntermediatePlate) && (
                <EquipmentSwitch
                  disabled={disabledSwitches.includes('IntermediatePlate')}
                  description={
                    availableDevices.find(d => isSubtype(d.subtype, ['IntermediatePlate']))
                      ? availableDevices.find(d => isSubtype(d.subtype, ['IntermediatePlate'])).translations[langId].name
                      : ''
                  }
                  status={usedDevices.IntermediatePlate}
                  label={Localizations['intermediatePlate'][lang]}
                  toggleStatus={() => {
                    if (!usedDevices.IntermediatePlate) {
                      EquipmentHelper.setIntermediatePlate(
                        selection,
                        availableDevices.find(d => isSubtype(d.subtype, ['IntermediatePlate']))
                      );
                    } else {
                      EquipmentHelper.setIntermediatePlate(selection, null);
                    }
                    setUsedDevices({ ...usedDevices, IntermediatePlate: !usedDevices.IntermediatePlate });
                  }}
                  type="first"
                />
              )}
              {(availableSubtypes.includes(Subtype.EOven) || availableSubtypes.includes(Subtype.GOven)) && (
                <>
                  <h5 className="mb-1">{Localizations['oven'][lang]}</h5>
                  <Selectbox
                    disabled={!(EquipmentHelper.canHaveEOven(selection) || EquipmentHelper.canHaveGOven(selection))}
                    state={selectedOven}
                    setState={setSelectedOven}
                    options={[...ovens.map(o => o.translations[langId].name), Localizations['none'][lang]]}
                  />
                </>
              )}
            </div>
          )}
        </>
      )}
    </Fragment>
  );
};
