import * as BABYLON from 'babylonjs';
import { Mesh } from 'babylonjs';
import Equipment, { EquipmentHelper } from 'page/Editor/configuration/Equipment';
import { DeviceComponent } from 'types/Device';
import { Subtype } from 'types/DeviceEnum';
import Dimensions from 'utils/Dimensions';
import BasicUtils from '../util/BasicUtils';
import BlockGroupNode from './BlockGroupNode';
import DeviceNode, { PropertyType } from './DeviceNode';
import { toSubtype } from 'utils';

export class OptionNode extends BABYLON.TransformNode {
  private _container: DeviceNode | BlockGroupNode;
  private _deviceComponent: DeviceComponent;
  private _model: BABYLON.TransformNode;
  private _equipment: Equipment;

  constructor(node: BABYLON.TransformNode, deviceComponent: DeviceComponent) {
    super('option.' + deviceComponent.component.id, node.getScene(), undefined);
    this._model = node;
    this._model.parent = this;
    this._deviceComponent = deviceComponent;
  }

  public prepareType() {
    if (this._deviceComponent && this._container) {
      let targetWidth = this._container.get(PropertyType.Width) as number;

      // mm to cm
      const width = targetWidth / 10;
      // cm to scaling
      const scaling = width / 10;

      if (this._equipment) {
        let deviceSubtype: string | Subtype = this._equipment.getDeviceObject().subtype;
        if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
        switch (deviceSubtype) {
          case Subtype.Hygiene:
          case Subtype.AngularRail:
          case Subtype.EmptyingSystem:
          case Subtype.HeatingCabinet:
          case Subtype.HeatingDrawer:
          case Subtype.LowerDrawer:
          case Subtype.WingedDoor:
            if (this._container instanceof DeviceNode) {
              if (this._container.getBlockItem()) {
                targetWidth = this._container.getBlockItem().getWidthSubstructure();
              } else if (this._container.getFreeItem()) {
                targetWidth = this._container.getFreeItem().getWidthSubstructure();
              }
            } else if (this._container instanceof BlockGroupNode) {
              targetWidth = this._container.getBlockGroup().getWidthSubstructure();
            }
            break;
          case Subtype.BaseCover:
            if (this._container instanceof DeviceNode) {
              const device = this._container.getDeviceObject();
              if (device) {
                if (this._equipment.getIndex() === 1) {
                  if (device.style.indexOf('S=1-') >= 0) {
                    this.position.x = 130;
                  } else if (device.style.indexOf('S=2-') >= 0) {
                    this.position.x = 150;
                  } else if (device.style.indexOf('S=3-') >= 0) {
                    this.position.x = 180;
                  }
                } else {
                  if (device.style.indexOf('Left') >= 0) {
                    this.position.x = 20;
                  }
                }
              }
            }
            break;
          case Subtype.ReadyXpress:
            if (this._container instanceof DeviceNode) {
              const device = this._container.getDeviceObject();
              const s1 = 110;
              const s2 = 130;
              const s3 = 160;
              const d = 25;
              const d2 = 45;
              if (this._equipment.getIndex() === 1) {
                if (device.style.indexOf('S=1-1') >= 0) {
                  // Nothing
                } else if (device.style.indexOf('S=1-2') >= 0) {
                  this.position.x = s1 + s2 - d;
                } else if (device.style.indexOf('S=1-3') >= 0) {
                  this.position.x = s1 + s3 - d;
                } else if (device.style.indexOf('S=2-1') >= 0) {
                  // Nothing
                } else if (device.style.indexOf('S=2-2') >= 0) {
                  this.position.x = s2 + s2 - d;
                } else if (device.style.indexOf('S=2-3') >= 0) {
                  this.position.x = s2 + s3 - d;
                } else if (device.style.indexOf('S=3-1') >= 0) {
                  // Nothing
                } else if (device.style.indexOf('S=3-2') >= 0) {
                  this.position.x = s3 + s2 - d;
                } else if (device.style.indexOf('S=3-3') >= 0) {
                  this.position.x = s3 + s3 - d;
                }
              } else {
                if (device.style.indexOf('Left') >= 0) {
                  if (device.style.indexOf('S=1') >= 0) {
                    this.position.x = s1 - d;
                  } else if (device.style.indexOf('S=2') >= 0) {
                    this.position.x = s2 - d;
                  } else if (device.style.indexOf('S=3') >= 0) {
                    this.position.x = s3 - d;
                  }
                } else {
                  if (device.style.indexOf('S=1') >= 0) {
                    this.position.x = s1 - d2;
                  } else if (device.style.indexOf('S=2') >= 0) {
                    this.position.x = s2 - d2;
                  } else if (device.style.indexOf('S=3') >= 0) {
                    this.position.x = s3 - d2;
                  }
                }
              }
            }
            break;
          case Subtype.FlexiCombiAir:
          case Subtype.MagicHood:
            if (this._container instanceof DeviceNode) {
              const device = this._container.getDeviceObject();
              if (device) {
                this._model.position.y = Dimensions.CM(device.model.height);
              }
            }
            break;
        }
      }

      switch (this._deviceComponent.component.id) {
        // WingedDoor
        case '7KP0K':
          {
            // To fix a wired bug we put the model under a fresh node....
            const front = new BABYLON.TransformNode('Front', this.getScene());
            front.parent = this;
            BasicUtils.findFirstChild('door', this._model).parent = front;
            this._model.dispose();
            this._model = front;

            if ('Door2' == this._equipment?.getDeviceObject()?.style) {
              const halfWidth = width / 2;
              const halfScaling = scaling / 2;
              const doorLeft = BasicUtils.findFirstChild('door', this._model);
              const doorRight = BasicUtils.clone(doorLeft, 'DoorRight');
              // Left
              {
                BasicUtils.findFirstChild('Center', doorLeft).scaling.x = Math.max(0, halfScaling - 2);
                const right = BasicUtils.findFirstChild('Right', doorLeft);
                right.position.x = halfWidth - 20;
              }
              // Right
              {
                BasicUtils.findFirstChild('Center', doorRight).scaling.x = Math.max(0, halfScaling - 2);
                const right = BasicUtils.findFirstChild('Right', doorRight);
                right.position.x = halfWidth - 20;
                doorRight.scaling.x = -1;
                doorRight.position.x = width;
              }
            } else {
              BasicUtils.findFirstChild('Center', this._model).scaling.x = Math.max(0, scaling - 2);
              const right = BasicUtils.findFirstChild('Right', this._model);
              right.position.x = width - 20;
            }

            const back = BasicUtils.clone(this._model, 'Back');
            back.scaling.z = -1;
            back.getChildTransformNodes().forEach(child => {
              if (child.name === 'Flex') {
                child.name = 'NoFlex';
                child.position.z = 0;
              }
            });
          }
          break;
        // UpperDrawer
        case 'd2nB2':
        // Zwischen boden
        case 'Kov1L':
        case 'L38zK':
          {
            BasicUtils.findFirstChild('Center', this._model).scaling.x = Math.max(0, scaling - 2);
            const left = BasicUtils.findFirstChild('Left', this._model);
            const right = BasicUtils.clone(left, 'Right');
            right.scaling.x = -1;
          }
          break;
        // Drawer 1x & 2x
        case 'WKOJL':
        case 'R2QoL':
          {
            BasicUtils.findFirstChild('Center', this._model).scaling.x = Math.max(0, scaling - 2);
          }
          break;
        // Hygiene
        case 'OK7l2':
          {
            //const node = BasicUtils.findFirstChild('Hygiene', this._model);
            this._model.name = 'HygieneClosed';
            BasicUtils.findFirstChild('Center', this._model).scaling.x = Math.max(0, scaling);
            const left = BasicUtils.findFirstChild('Left', this._model);
            const right = BasicUtils.clone(left, 'Right');
            right.scaling.x = -1;

            const open = BasicUtils.clone(this._model, 'HygieneOpen');
            BasicUtils.findFirstChild('Center', open).dispose();
            BasicUtils.findFirstChild('Left:Shared', open).dispose();
            BasicUtils.findFirstChild('Left:Extension', open).dispose();
            BasicUtils.findFirstChild('Right:Shared', open).dispose();
            BasicUtils.findFirstChild('Right:Extension', open).dispose();
            const openLeftFlex = BasicUtils.clone(BasicUtils.findFirstChild('Left:Flex', open), 'Shared');
            openLeftFlex.scaling.z = -1.5;
            openLeftFlex.position.z = -120;
            const openRightFlex = BasicUtils.clone(BasicUtils.findFirstChild('Right:Flex', open), 'Shared');
            openRightFlex.scaling.z = openLeftFlex.scaling.z;
            openRightFlex.position.z = openLeftFlex.position.z;
          }
          break;
        // AngularRail
        case 'wKBAL':
          {
            const node = BasicUtils.findFirstChild('URail', this._model);
            const left = BasicUtils.findFirstChild('Left', node);
            const right = BasicUtils.clone(left, 'Right');
            right.scaling.x = -1;
          }
          break;
        // UpturnBack
        case '28OwK':
          {
            const left = BasicUtils.findFirstChild('Left', this._model);
            const right = BasicUtils.clone(left, 'Right');
            right.scaling.x = -1;
          }
          break;
      }
    }

    if (this._container?.getBlock()?.getBlockType() === 'ModularNOL') {
      if (this._equipment) {
        switch (toSubtype(this._equipment.getDeviceObject().subtype)) {
          case Subtype.Hygiene:
          case Subtype.AngularRail:
          case Subtype.EmptyingSystem:
          case Subtype.HeatingCabinet:
          case Subtype.HeatingDrawer:
          case Subtype.LowerDrawer:
          case Subtype.WingedDoor:
          case Subtype.EOven:
          case Subtype.GOven:
            this._model.scaling.y = 1.12;
            break;
        }
      }
    }

    this.prepareTypeWidthChange();
    this.prepareTypeDepthChange();
    this.prepareOptionChange();
  }

  public prepareTypeWidthChange() {
    if (this._deviceComponent && this._container) {
      let targetWidth = this._container.get(PropertyType.Width) as number;

      // mm to cm
      const width = targetWidth / 10;
      // cm to scaling
      const scaling = width / 10;

      if (this._equipment) {
        let deviceSubtype: string | Subtype = this._equipment.getDeviceObject().subtype;
        if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
        switch (deviceSubtype) {
          case Subtype.Hygiene:
          case Subtype.AngularRail:
          case Subtype.EmptyingSystem:
          case Subtype.HeatingCabinet:
          case Subtype.HeatingDrawer:
          case Subtype.LowerDrawer:
          case Subtype.WingedDoor:
            if (this._container instanceof DeviceNode) {
              if (this._container.getBlockItem()) {
                targetWidth = this._container.getBlockItem().getWidthSubstructure();
              } else if (this._container.getFreeItem()) {
                targetWidth = this._container.getFreeItem().getWidthSubstructure();
              }
            } else if (this._container instanceof BlockGroupNode) {
              targetWidth = this._container.getBlockGroup().getWidthSubstructure();
            }
            break;
          case Subtype.BaseCover:
            if (this._container instanceof DeviceNode) {
              const device = this._container.getDeviceObject();
              if (device) {
                if (this._equipment.getIndex() === 1) {
                  if (device.style.indexOf('S=1-') >= 0) {
                    this.position.x = 130;
                  } else if (device.style.indexOf('S=2-') >= 0) {
                    this.position.x = 150;
                  } else if (device.style.indexOf('S=3-') >= 0) {
                    this.position.x = 180;
                  }
                } else {
                  if (device.style.indexOf('Left') >= 0) {
                    this.position.x = 20;
                  }
                }
              }
            }
            break;
          case Subtype.ReadyXpress:
            if (this._container instanceof DeviceNode) {
              const device = this._container.getDeviceObject();
              const s1 = 110;
              const s2 = 130;
              const s3 = 160;
              const d = 25;
              const d2 = 45;
              if (this._equipment.getIndex() === 1) {
                if (device.style.indexOf('S=1-1') >= 0) {
                  // Nothing
                } else if (device.style.indexOf('S=1-2') >= 0) {
                  this.position.x = s1 + s2 - d;
                } else if (device.style.indexOf('S=1-3') >= 0) {
                  this.position.x = s1 + s3 - d;
                } else if (device.style.indexOf('S=2-1') >= 0) {
                  // Nothing
                } else if (device.style.indexOf('S=2-2') >= 0) {
                  this.position.x = s2 + s2 - d;
                } else if (device.style.indexOf('S=2-3') >= 0) {
                  this.position.x = s2 + s3 - d;
                } else if (device.style.indexOf('S=3-1') >= 0) {
                  // Nothing
                } else if (device.style.indexOf('S=3-2') >= 0) {
                  this.position.x = s3 + s2 - d;
                } else if (device.style.indexOf('S=3-3') >= 0) {
                  this.position.x = s3 + s3 - d;
                }
              } else {
                if (device.style.indexOf('Left') >= 0) {
                  if (device.style.indexOf('S=1') >= 0) {
                    this.position.x = s1 - d;
                  } else if (device.style.indexOf('S=2') >= 0) {
                    this.position.x = s2 - d;
                  } else if (device.style.indexOf('S=3') >= 0) {
                    this.position.x = s3 - d;
                  }
                } else {
                  if (device.style.indexOf('S=1') >= 0) {
                    this.position.x = s1 - d2;
                  } else if (device.style.indexOf('S=2') >= 0) {
                    this.position.x = s2 - d2;
                  } else if (device.style.indexOf('S=3') >= 0) {
                    this.position.x = s3 - d2;
                  }
                }
              }
            }
            break;
          case Subtype.FlexiCombiAir:
          case Subtype.MagicHood:
            if (this._container instanceof DeviceNode) {
              const device = this._container.getDeviceObject();
              if (device) {
                this._model.position.y = Dimensions.CM(device.model.height);
              }
            }
            break;
        }
      }

      switch (this._deviceComponent.component.id) {
        // UpperDrawer
        case 'd2nB2':
        // Zwischen boden
        case 'Kov1L':
        case 'L38zK':
          {
            BasicUtils.findFirstChild('Center', this._model).scaling.x = Math.max(0, scaling - 2);
            const right = (BasicUtils.findFirstChild('Right', this._model).position.x = width);
          }
          break;
        // Drawer 1x & 2x
        case 'WKOJL':
        case 'R2QoL':
          {
            BasicUtils.findFirstChild('Center', this._model).scaling.x = Math.max(0, scaling - 2);
            BasicUtils.findFirstChild('Right', this._model).position.x = width - 20;
          }
          break;
        // Hygiene
        case 'OK7l2':
          {
            //const node = BasicUtils.findFirstChild('Hygiene', this._model);
            BasicUtils.findFirstChild('Center', this._model).scaling.x = Math.max(0, scaling);
            BasicUtils.findFirstChild('Right', this._model).position.x = width;

            const open = BasicUtils.findFirstChild('HygieneOpen', this);
            BasicUtils.findFirstChild('Right', open).position.x = width;
          }
          break;
        // AngularRail
        case 'wKBAL':
          {
            const node = BasicUtils.findFirstChild('URail', this._model);
            BasicUtils.findFirstChild('Right', node).position.x = width;
          }
          break;
        // MixingFaucet
        case 'g28wK':
        // Kaltwasserzulauf
        case '2ZyYK':
          {
            this._model.position.x = width / 2;
          }
          break;
        // EOven
        case 'GLzd2':
        // GOven
        case 'eKRML':
        // GOvenExhaust
        case '32bxL':
          if (targetWidth > 800) {
            this._model.position.x = width - 80;
          }
          break;
        // Emptying System
        case 'E4mx2':
          const esw = 26.848;
          this._model.scaling.x = (width - 5) / esw;
          break;
        // UpturnBack
        case '28OwK':
          {
            BasicUtils.findFirstChild('Center', this._model).scaling.x = Math.max(0, scaling - 2);
            BasicUtils.findFirstChild('Right', this._model).position.x = Math.max(0, width - 20);
          }
          break;
        // Flexi
        case 'g4XmL':
        case 'k2VxL':
        case 'dKpgL':
          if (this.getDeviceComponent().scaling.x == -1) {
            const mesh = BasicUtils.findFirstChild('display', this) as Mesh;
            mesh.material = this.getScene().getMaterialByName(mesh.material.name + '_m');
            const klappe = BasicUtils.findFirstChild('sc_logo:klappe', this) as Mesh;
            if (klappe) klappe.material = this.getScene().getMaterialByName(klappe.material.name + '_m');
          }
          break;
        // GN Gestell
        case 'LzndL':
          const gnw = 45;
          if (this._equipment && this._equipment.getIndex() == 1) {
            this._model.position.x = width - gnw;
          } else if (width < 70) {
            this._model.position.x = width / 2 - gnw / 2;
          }
          break;
      }
    }
  }

  public prepareTypeDepthChange() {
    if (this._deviceComponent && this._container) {
      const depth = this._container.get(PropertyType.Depth) as number;
      switch (this._deviceComponent.component.id) {
        // WingedDoor
        case '7KP0K':
          {
            const back = BasicUtils.findFirstChild('Back', this);
            back.position.z = depth == 700 ? -95 : -80;
            BasicUtils.computeAllWorldMatrix(back);
          }
          break;
        // Hygiene
        case 'OK7l2':
        // AngularRail
        case 'wKBAL':
          {
            if (depth === 700) {
              this.getChildTransformNodes(false, n => n.name === 'Left').forEach(n =>
                n.getChildTransformNodes(false, n => n.name === 'Flex').forEach(n => (n.position.z = 15))
              );
              this.getChildTransformNodes(false, n => n.name === 'Right').forEach(n =>
                n.getChildTransformNodes(false, n => n.name === 'Flex').forEach(n => (n.position.z = 15))
              );
            } else {
              this.getChildTransformNodes(false, n => n.name === 'Left').forEach(n =>
                n.getChildTransformNodes(false, n => n.name === 'Flex').forEach(n => (n.position.z = 0))
              );
              this.getChildTransformNodes(false, n => n.name === 'Right').forEach(n =>
                n.getChildTransformNodes(false, n => n.name === 'Flex').forEach(n => (n.position.z = 0))
              );
            }
          }
          break;
        // GN Gestell
        case 'LzndL':
          this._model.getChildTransformNodes(true).forEach(n => (n.position.z = -17));
          break;
        // Warmhalteschublade
        case 'KRRMK':
        // Warmhaltegerät
        case '2baxK':
          this._model.getChildTransformNodes(true).forEach(n => (n.position.z = -15));
          break;
        // Zwischen boden
        case 'Kov1L':
        case 'L38zK':
          {
            if (depth === 700) {
              this._model.getChildTransformNodes().forEach(n => (n.name === 'Shared' ? (n.position.z = -15) : ''));
            } else {
              this._model.getChildTransformNodes().forEach(n => (n.name === 'Shared' ? (n.position.z = 0) : ''));
            }
          }
          break;
      }
      if (this._equipment) {
        switch (this._equipment.getDeviceId()) {
          // Cold Water Inlet
          case 'lnNdl':
            {
              if (depth === 700) {
                this._model.position.z = -1;
              } else {
                this._model.position.z = this.getDeviceComponent().position.z;
              }
              console.log('ModelZ', this._model.position.z);
            }
            break;
        }
      }
    }
    BasicUtils.computeAllWorldMatrix(this);
  }

  public prepareOptionChange() {
    if (this._deviceComponent && this._container) {
      let targetWidth = this._container.get(PropertyType.Width) as number;

      // mm to cm
      const width = targetWidth / 10;
      // cm to scaling
      const scaling = width / 10;

      if (this._equipment) {
        let deviceSubtype: string | Subtype = this._equipment.getDeviceObject().subtype;
        if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
        switch (deviceSubtype) {
          case Subtype.UpperPowerSocket:
            if (this._container instanceof DeviceNode) {
              const e0 = EquipmentHelper.getEquipment(this._container.getBlockItem() || this._container.getFreeItem(), Subtype.UpperPowerSocket, 0);
              const e1 = EquipmentHelper.getEquipment(this._container.getBlockItem() || this._container.getFreeItem(), Subtype.UpperPowerSocket, 1);
              if (e0 && e1) {
                if (this._equipment.getIndex() === 1) {
                  this._model.position.x = 3 * (width / 4);
                } else {
                  this._model.position.x = width / 4;
                }
              } else {
                this._model.position.x = width / 2;
              }
            }
            break;
        }
      }
    }
    this.prepareTypeDepthChange();
    // BasicUtils.computeAllWorldMatrix(this);
  }

  public getDeviceComponent() {
    return this._deviceComponent;
  }

  public getModel() {
    return this._model;
  }

  public setContainer(container: DeviceNode | BlockGroupNode) {
    this._container = container;
  }

  public getEquipment() {
    return this._equipment;
  }

  public setEquipment(equipment: Equipment) {
    this._equipment = equipment;
  }
}
