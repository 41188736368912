import { FC, Fragment, useContext } from 'react';

// Atoms
import { Button } from 'atoms';

// Components
import { BlockSelector, LoadingSpinner } from 'components';
import { CategoryMenu, ContextMenu, Subcategory } from 'page/Editor/Menu/Shared';

// Context
import { EditorContext, LangContext } from 'context';

// Types:
import { Category, Device } from 'types';

// Styles
import '../../style.scss';

// ===================================================================

interface Props {
  selectedCategory: Category;
  singleView?: boolean;
  noSeries?: boolean;
  extraGoBack?: boolean;
  setSelection?: (device: Device) => void;
  setSelectedCategory: (category: Category) => void;
}

// ===================================================================
export const SelectedCategory: FC<Props> = ({ singleView = false, extraGoBack, noSeries = false, selectedCategory, setSelectedCategory }) => {
  const { menuView, searching, seriesFilter, setSeriesFilter, setSelection } = useContext(EditorContext);
  const { langId } = useContext(LangContext);

  const subCategories = selectedCategory.children ? selectedCategory.children : [];

  return (
    <Fragment>
      <ContextMenu>
        <div className="Functional-View-ContextMenu">
          {!singleView && (
            <div className="Functional-View-ContextMenu-Series">
              {!noSeries && (
                <>
                  <span style={{ marginRight: '.2rem' }}>Serie</span>
                  <Button
                    btnType={seriesFilter === '700' || seriesFilter === 'all' ? 'third' : 'fourth'}
                    fontSize=".6rem"
                    onClick={() => setSeriesFilter(seriesFilter === '700' ? 'all' : seriesFilter === 'all' ? '850' : 'all')}
                    margin="0 1px 0 0"
                    padding=".25rem"
                    thinBorder
                    width="50px"
                  >
                    700
                  </Button>
                  <Button
                    btnType={seriesFilter === '850' || seriesFilter === 'all' ? 'third' : 'fourth'}
                    fontSize=".6rem"
                    margin="0 0 0 1px"
                    onClick={() => setSeriesFilter(seriesFilter === '850' ? 'all' : seriesFilter === 'all' ? '700' : 'all')}
                    padding=".25rem"
                    thinBorder
                    width="50px"
                  >
                    850
                  </Button>
                </>
              )}
            </div>
          )}

          {/*  {!singleView && !fullscreen && (
            <div className="Functional-View-ContextMenu-Fullscreen">
              <img
                alt=""
                onClick={() => setFullscreen(fullscreen === 'menu' ? null : 'menu')}
                src={Expand}
                style={{ cursor: 'pointer', height: '16px', width: '16px' }}
              />
            </div>
          )} */}
        </div>
      </ContextMenu>
      {extraGoBack && (
        <div style={{ paddingTop: '3rem' }}>
          <ContextMenu
            noMargin
            mode="back"
            onClick={() => {
              setSelectedCategory(null);
            }}
          />
        </div>
      )}
      {(menuView === 'modular' || menuView === 'masterline' || menuView === 'marineMeister' || menuView === 'modularNOL') && (
        <div style={{ paddingTop: '3rem' }}>
          <BlockSelector />
        </div>
      )}
      {searching && (
        <div className="mt-4">
          <LoadingSpinner color="blue" />
        </div>
      )}
      {!searching && subCategories && subCategories.length > 0 ? (
        <div
          className="h-100"
          style={{
            paddingTop: !(menuView === 'modular' || menuView === 'masterline' || menuView === 'marineMeister' || menuView === 'modularNOL') ? '3rem' : '1rem'
          }}
        >
          {singleView || extraGoBack ? (
            <div className="Functional-Menu-Subsection-Title">
              <span>{selectedCategory ? selectedCategory.translations[langId].name : ''}</span>
            </div>
          ) : (
            <ContextMenu border noMargin mode="back" onClick={() => setSelectedCategory(null)}>
              <div className="ml-1 flex align-center">
                <div className="Functional-Menu-Subsection-Title">
                  <span>{selectedCategory ? selectedCategory.translations[langId].name : ''}</span>
                </div>
              </div>
            </ContextMenu>
          )}

          <div
            className="Custom-Scroll"
            style={{
              maxHeight: ['modular', 'masterline'].includes(menuView)
                ? 'calc(100% - 135px)'
                : menuView === 'marineMeister'
                ? 'calc(100% - 100px)'
                : 'calc(100% - 35px)'
            }}
          >
            {subCategories.map((sC: Category) => {
              if (
                sC.deviceCount &&
                (seriesFilter === '700' ? sC.deviceCount._700 > 0 : seriesFilter === '850' ? sC.deviceCount._850 > 0 : sC.deviceCount.total > 0)
              ) {
                return <Subcategory key={`Subkategory${sC.id}`} category={sC} selectDevice={setSelection} setSeries={setSeriesFilter} />;
              } else return <div key={`no devices${sC.id}`}></div>;
            })}
          </div>
        </div>
      ) : searching ? (
        <></>
      ) : (
        <div className="h-100" style={{ paddingTop: '1rem' }}>
          <div className="Custom-Scroll" style={{ maxHeight: 'calc(100% - 75px)' }}>
            <CategoryMenu category={selectedCategory} detailedView selectDevice={setSelection} onSelect={() => setSelectedCategory(null)} />
          </div>
        </div>
      )}
    </Fragment>
  );
};
