import { useEffect, useState } from 'react';
import { Device, Subtype } from 'types';
import { post } from 'utils';
import { prefix } from 'utils/getDevice';

export const HYGIENE_STORAGE_KEY = prefix + '-hygieneDevices';

export const useGetHygieneDevices = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hygieneDevices, setHygieneDevices] = useState<Device[]>([]);

  useEffect(() => {
    const getHygieneDevices = async () => {
      setIsLoading(true);
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/device/equipment`, {
        data: {
          type: 'masterline',
          subtypes: [Subtype.Hygiene]
        }
      });
      if (data) {
        console.log('DATA: ', data);
        setHygieneDevices(data);
        sessionStorage.setItem(HYGIENE_STORAGE_KEY, JSON.stringify(data));
        setIsLoading(false);
      }
      if (error) {
        setIsLoading(false);
        console.error(error);
      }
    };

    const storageItem = sessionStorage.getItem(HYGIENE_STORAGE_KEY);
    if (storageItem) {
      setHygieneDevices(JSON.parse(storageItem));
    } else {
      getHygieneDevices();
    }
  }, []);

  return {
    isLoading,
    hygieneDevices,
    setHygieneDevices
  };
};
