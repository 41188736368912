import Block from 'page/Editor/configuration/Block';
import { Device, Subtype } from 'types';
import { isSubtype } from 'utils/subtype';

export const isSubstructureClashing = (device: Device, block: Block) => {
  const isUpperStructureOnly = !!block?.getUpperstrcutureOnly();
  const isDeviceSubstrctureFixed = device.dependency.equipmentCombinations.some(equipmentCombination =>
    isSubtype(equipmentCombination, [Subtype.HasFixedSubstructure])
  );

  return isUpperStructureOnly && isDeviceSubstrctureFixed;
};
