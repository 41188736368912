import { Checkbox } from 'atoms';
import { NumericInput } from 'components';
import { EditorContext, LangContext } from 'context';
import Localizations from 'languages';
import BlockItem from 'page/Editor/configuration/BlockItem';
import { useContext, useEffect, useState } from 'react';

type CutOutState = {
  top: number;
  bottom: number;
  left: number;
  right: number;
};

export const CutOutInputs = () => {
  const { lang } = useContext(LangContext);
  const { selection } = useContext(EditorContext);

  const device = selection instanceof BlockItem ? selection.getDeviceObject() : null;
  const maxXCutOut = device.model.width - 100;
  const maxYCutOut = selection instanceof BlockItem && selection.getDepth() === 850 ? 700 : 550;

  const [isCutOutActive, setIsCutOutActive] = useState(false);
  const [cutOut, setCutOut] = useState<CutOutState>({
    top: 0,
    bottom: 100,
    left: 0,
    right: 0
  });

  const handleCutOutChange = ({ position, value }: { position: 'top' | 'bottom' | 'left' | 'right'; value: number }) => {
    if (position === 'top') {
      setCutOut(prev => ({ ...prev, top: value }));
    } /* else if (position === 'bottom') {
      setCutOut(prev => ({ ...prev, bottom: value }));
    } */
    if (position === 'left') {
      setCutOut(prev => ({ ...prev, left: value }));
    }
    if (position === 'right') {
      setCutOut(prev => ({ ...prev, right: value }));
    }
    if (selection instanceof BlockItem) {
      selection.setCutOut({
        t: cutOut.top,
        b: cutOut.bottom,
        l: cutOut.left,
        r: cutOut.right
      });
    }
  };

  const handleToggleCutOut = (newValue: boolean) => {
    setIsCutOutActive(newValue);
    if (selection instanceof BlockItem) {
      if (!newValue) {
        selection.setCutOut(null);
      } else {
        selection.setCutOut({
          t: cutOut.top,
          b: cutOut.bottom,
          l: cutOut.left,
          r: cutOut.right
        });
      }
    }
  };

  useEffect(() => {
    if (selection instanceof BlockItem) {
      const sCutOut = selection.getCutOut();
      setIsCutOutActive(!!sCutOut);
      setCutOut({
        top: sCutOut?.t ?? 0,
        bottom: sCutOut?.b ?? 100,
        left: sCutOut?.l ?? 0,
        right: sCutOut?.r ?? 0
      });
    }
  }, [selection]);

  return (
    <div>
      <h5 className="mb-1">{Localizations['cutOut'][lang]}</h5>
      <div className="mb-2 mt-2">
        <Checkbox
          boxSize="1rem"
          centered={false}
          id="upperStructure-check"
          label={Localizations['isCutOutActive'][lang]}
          labelFontSize=".85rem"
          labelColor="black"
          status={isCutOutActive}
          setStatus={v => handleToggleCutOut(v)}
        />
      </div>
      {isCutOutActive && (
        <>
          <NumericInput
            className="Submenu-Input mt-2"
            labelClassName="Submenu-Label"
            label={`${Localizations['distanceBack'][lang]} (mm)`}
            min={0}
            max={maxYCutOut - cutOut.bottom}
            step={1}
            value={cutOut.top}
            onChange={v => handleCutOutChange({ position: 'top', value: Number(v) })}
            validateOnBlur
          />
          <NumericInput
            className="Submenu-Input mt-2"
            labelClassName="Submenu-Label"
            label={`${Localizations['distanceRight'][lang]} (mm)`}
            min={0}
            max={maxXCutOut - cutOut.left}
            step={1}
            value={cutOut.right}
            onChange={v => handleCutOutChange({ position: 'right', value: Number(v) })}
            validateOnBlur
          />
          <NumericInput
            className="Submenu-Input mt-2"
            isDisabled
            labelClassName="Submenu-Label"
            label={`${Localizations['distanceFront'][lang]} (mm)`}
            min={0}
            max={maxYCutOut - cutOut.top}
            step={1}
            value={cutOut.bottom}
            onChange={v => handleCutOutChange({ position: 'bottom', value: Number(v) })}
            validateOnBlur
          />
          <NumericInput
            className="Submenu-Input mt-2"
            labelClassName="Submenu-Label"
            label={`${Localizations['distanceLeft'][lang]} (mm)`}
            min={0}
            max={maxXCutOut - cutOut.right}
            step={1}
            value={cutOut.left}
            onChange={v => handleCutOutChange({ position: 'left', value: Number(v) })}
            validateOnBlur
          />
        </>
      )}
    </div>
  );
};
