import BlockGroup from 'page/Editor/configuration/BlockGroup';
import BlockItem from 'page/Editor/configuration/BlockItem';
import { Device } from 'types';
import { post } from 'utils/AJAX';

export const getNOLWingedDoorDevice = async (addBlock: BlockItem | BlockGroup): Promise<Device> => {
  try {
    const { data } = await post(`${process.env.REACT_APP_API_URL}/device/equipment`, {
      data: {
        type: 'masterline',
        width: 1200,
        depth: addBlock instanceof BlockItem ? addBlock.getDepth() : addBlock.getItems()[0]?.getDepth(),
        subtypes: ['WingedDoor']
      }
    });
    if (data) return data[0];
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};
