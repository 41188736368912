import BlockNode from 'components/babylon/node/BlockNode';
import BlockItem from 'page/Editor/configuration/BlockItem';
import BlockGroup from 'page/Editor/configuration/BlockGroup';
import Block from 'page/Editor/configuration/Block';

import { Device, DeviceSeries, ErrorAlert, Subtype } from 'types';
import { isFryer, post } from 'utils';
import { EquipmentHelper } from 'page/Editor/configuration/Equipment';
import { HYGIENE_STORAGE_KEY } from 'page/Editor/Menu/Menus/Masterline/Partials/Structure/hooks/useGetHygieneDevices';
import { getNOLWingedDoorDevice } from 'utils/getNOLWingedDoorDevice';

export const addDevice = async (
  scene: any,
  device: Device,
  setDialog: (dialog: 'battery' | 'socket' | 'oven' | null) => void,
  setErrorAlert: (err: ErrorAlert[]) => void,
  setCoverEnlargementSize: (sizeMessage: string) => void,
  filter?: DeviceSeries,
  block?: Block,
  shouldAddWingedDoor = false
) => {
  let addBlock = block;
  if (!block) {
    const blocks = scene._scene.getTransformNodesByID('block');
    for (let i = 0; i < blocks.length; i++) {
      const b = blocks[i];
      if (b instanceof BlockNode) {
        addBlock = b.getBlock();
      }
    }
  }

  const topRow = addBlock.getRowTop();
  const bottomRow = addBlock.getRowBottom();

  const shouldAddToTop = topRow && topRow.getDepth().toString() === filter && addBlock.canItemBeDropped(device, topRow);
  const shouldAddToBottom = bottomRow && bottomRow.getDepth().toString() === filter && addBlock.canItemBeDropped(device, bottomRow);

  if (device?.dependency?.coverEnlargementSize > 0) {
    setCoverEnlargementSize(`${device.dependency.coverEnlargementSize}mm.`);
  }

  if (addBlock) {
    let addedBlockItem: BlockItem | BlockGroup;
    const errorAlerts: ErrorAlert[] = [];

    if ((shouldAddToTop || filter === 'all') && addBlock.canItemBeDropped(device, topRow)) {
      addedBlockItem = topRow.addItem(device);

      if (addBlock.isAutoHygiene() && EquipmentHelper.canHaveHygiene(addedBlockItem)) {
        await addHygiene(addedBlockItem, topRow.getDepth());
      }

      if (device.dependency.coverEnlargement && device.model.masterline) {
        errorAlerts.push('coverEnlargement');
      }

      if (isFryer(device.category)) {
        errorAlerts.push('fryer');
      }
    } else if (shouldAddToBottom || (filter === 'all' && addBlock.canItemBeDropped(device, bottomRow))) {
      addedBlockItem = bottomRow.addItem(device);
      if (addBlock.isAutoHygiene() && EquipmentHelper.canHaveHygiene(addedBlockItem)) {
        await addHygiene(addedBlockItem, bottomRow.getDepth());
      }

      if (device.dependency.coverEnlargement && device.model.masterline) {
        errorAlerts.push('coverEnlargement');
      }
      if (isFryer(device.category)) {
        errorAlerts.push('fryer');
      }
    } else {
      errorAlerts.push('size');
    }

    if (addedBlockItem && shouldAddWingedDoor && EquipmentHelper.canHaveWingedDoor(addedBlockItem)) {
      const wingedDoorDevice = await getNOLWingedDoorDevice(addedBlockItem);
      EquipmentHelper.setWingedDoor(addedBlockItem, wingedDoorDevice);
    }

    if (errorAlerts.length > 0) {
      setErrorAlert(errorAlerts);
    }
  }
};

export const addHygiene = async (item: BlockItem | BlockGroup, depth: number) => {
  const storageData = sessionStorage.getItem(HYGIENE_STORAGE_KEY);

  if (storageData) {
    const hygieneDevices = JSON.parse(storageData);
    const matchingHygiene = hygieneDevices.find((device: Device) => device.model.width === item.getWidth() && device.model.depths.includes(depth));

    if (matchingHygiene) {
      EquipmentHelper.addEquipment(item, matchingHygiene);
    }
    return;
  }

  const { data } = await post(`${process.env.REACT_APP_API_URL}/device/equipment`, {
    data: {
      type: 'masterline',
      depth,
      width: item.getWidth(),
      subtypes: [Subtype.Hygiene]
    }
  });

  const matchingHygiene = data[0];
  if (matchingHygiene) {
    EquipmentHelper.addEquipment(item, matchingHygiene);
  } else {
    console.error('No matching hygiene found');
  }
};
