import React, { FC } from 'react';

import './style.scss';

interface Props {
  disabled?: boolean;
  label?: string;
  options: number[] | string[] | { disabled?: boolean; label: string; value: string }[];
  state: any;
  setState: (val: any) => void;
  type?: 'first' | 'second';
}

export const Selectbox: FC<Props> = ({ disabled = false, label, options, state, setState, type = 'first' }) => {
  if (label) {
    return (
      <>
        <div className="mb-1">
          <label className="Selectbox-label">{label}</label>
        </div>
        <select
          className={type === 'first' ? 'Selectbox' : 'SelectboxSecond'}
          disabled={disabled}
          value={state}
          onChange={event => {
            setState(event.target.value);
          }}
        >
          {options.map((opt, index) => {
            return (
              <option
                disabled={Boolean(typeof opt === 'object' && opt?.disabled)}
                key={`option-selectbox-${index}`}
                value={typeof opt === 'object' ? opt.value : opt}
              >
                {typeof opt === 'object' ? opt.label : opt}
              </option>
            );
          })}
        </select>
      </>
    );
  } else {
    return (
      <select
        className={type === 'first' ? 'Selectbox' : 'SelectboxSecond'}
        disabled={disabled}
        value={state}
        onChange={event => {
          setState(event.target.value);
        }}
      >
        {options.map((opt, index) => {
          return (
            <option
              disabled={Boolean(typeof opt === 'object' && opt?.disabled)}
              key={`option-selectbox-${index}`}
              value={typeof opt === 'object' ? opt.value : opt}
            >
              {typeof opt === 'object' ? opt.label : opt}{' '}
            </option>
          );
        })}
      </select>
    );
  }
};
