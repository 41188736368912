import { EnergySource } from 'types/DeviceEnum';
import { getDevice } from 'utils';
import Block from './Block';
import BlockItem from './BlockItem';
import { EquipmentHelper } from './Equipment';
import FreeItem from './FreeItem';
import Room from './Room';
import RoomDoor from './RoomDoor';
import { RoomPillar } from './RoomPillar';
import { RoomWall } from './RoomWall';
import RoomWindow from './RoomWindow';
import Vector3 from './Vector3';

export default class Configuration {
  public clazz = 'Configuration';
  private id: string;

  private intro: string = null;
  private room: Room = null;
  private flexiChefs: Array<FreeItem> = [];
  private spaceCombis: Array<FreeItem> = [];
  private modular: Array<Block> = [];
  private modularNOL: Array<Block> = [];
  private masterline: Array<Block> = [];
  private marineMeister: Array<Block> = [];

  private discountFlexiChef: number;
  private discountSpaceCombi: number;
  private discountModular: number;
  private discountMasterline: number;

  private surchargeMasterline: number;

  private _showLabels: boolean = false;
  private _showConnections: boolean = false;
  private _showCutLabels: boolean = false;

  constructor(id?: string) {
    this.id = id;
    window['config'] = this; // TODO Remove this !!!!!!!!
  }

  static async importJSON(json: string | any): Promise<Configuration> {
    if (typeof json === 'string') json = JSON.parse(json);

    const prepareEquipment = async (target: FreeItem, sourceItem: any) => {
      if (sourceItem.equipments) {
        for (let i = 0; i < sourceItem.equipments.length; i++) {
          const equipment = sourceItem.equipments[i];
          if (equipment.clazz === 'Equipment') {
            const device = await getDevice(equipment.device);
            EquipmentHelper.addEquipment(target, device, equipment.index || 0);
          }
        }
      }
    };

    const config = new Configuration();
    if (json.clazz === 'Configuration') {
      config.id = json.id;

      config.discountFlexiChef = json.discountFlexiChef ? json.discountFlexiChef : 0;
      config.discountSpaceCombi = json.discountSpaceCombi ? json.discountSpaceCombi : 0;
      config.discountModular = json.discountModular ? json.discountModular : 0;
      config.discountMasterline = json.discountMasterline ? json.discountMasterline : 0;

      config.surchargeMasterline = json.surchargeMasterline ? json.surchargeMasterline : 0;

      if (json.room) {
        if (json.intro) config.setIntro(json.intro);
        // Import Room
        const room = new Room();
        room.setWidth(json.room.width);
        room.setDepth(json.room.depth);

        if (json.room.children && Array.isArray(json.room.children)) {
          for (let i = 0; i < json.room.children.length; i++) {
            const child = json.room.children[i];
            switch (child.clazz) {
              case 'RoomDoor':
                const door = new RoomDoor(room);
                door.setWidth(child.width);
                door.setHeight(child.height);
                door.setWall(child.wall);
                door.setPosition(child.position);
                break;
              case 'RoomPillar':
                const pillar = new RoomPillar(room);
                pillar.setWidth(child.width);
                pillar.setDepth(child.depth);
                pillar.setPosition(child.position);
                pillar.setRotation(child.rotation);
                break;
              case 'RoomWall':
                const wall = new RoomWall(room);
                wall.setWidth(child.width);
                wall.setHeight(child.height);
                wall.setDepth(child.depth);
                wall.setPosition(child.position);
                wall.setRotation(child.rotation);
                break;
              case 'RoomWindow':
                const window = new RoomWindow(room);
                window.setWidth(child.width);
                window.setHeight(child.height);
                window.setBottom(child.bottom);
                window.setWall(child.wall);
                window.setPosition(child.position);
                break;
              default:
                console.log('I am, what i am. (' + child.clazz + ')');
                break;
            }
          }
        }

        config.setRoom(room);
        // Noch mal bei 200°C für 10 min in den Ofen!
        room.getNode().bake();
      }
      // FlexiChefs
      if (json.flexiChefs && Array.isArray(json.flexiChefs)) {
        for (let i = 0; i < json.flexiChefs.length; i++) {
          const flexiChef = json.flexiChefs[i];
          if (flexiChef.clazz === 'FreeItem') {
            const device = await getDevice(flexiChef.device);
            if (device == null) continue;
            const item = new FreeItem(device, config);
            item.getNode().setPosition(flexiChef.position);
            item.setPosition(new Vector3(flexiChef.position.x, flexiChef.position.y, flexiChef.position.z));
            item.setRotation(new Vector3(flexiChef.rotation.x, flexiChef.rotation.y, flexiChef.rotation.z));
            await prepareEquipment(item, flexiChef);
          }
        }
      }
      // SpaceCombis
      if (json.spaceCombis && Array.isArray(json.spaceCombis)) {
        for (let i = 0; i < json.spaceCombis.length; i++) {
          const spaceCombi = json.spaceCombis[i];
          if (spaceCombi.clazz === 'FreeItem') {
            const device = await getDevice(spaceCombi.device);
            if (device == null) continue;
            const item = new FreeItem(device, config);
            item.getNode().setPosition(spaceCombi.position);
            item.setPosition(new Vector3(spaceCombi.position.x, spaceCombi.position.y, spaceCombi.position.z));
            item.setRotation(new Vector3(spaceCombi.rotation.x, spaceCombi.rotation.y, spaceCombi.rotation.z));
            await prepareEquipment(item, spaceCombi);
          }
        }
      }
      // Modular
      if (json.modular && Array.isArray(json.modular)) {
        for (let i = 0; i < json.modular.length; i++) {
          const modular = json.modular[i];
          if (modular.clazz === 'Block') config.getModular().push(await Block.importJSON(modular));
        }
      }
      // Modular New
      if (json.modularNOL && Array.isArray(json.modularNOL)) {
        for (let i = 0; i < json.modularNOL.length; i++) {
          const modularNOL = json.modularNOL[i];
          if (modularNOL.clazz === 'Block') config.getModularNOL().push(await Block.importJSON(modularNOL));
        }
      }
      // Masterline
      if (json.masterline) {
        if (Array.isArray(json.masterline)) {
          for (let i = 0; i < json.masterline.length; i++) {
            const masterline = json.masterline[i];
            if (masterline.clazz === 'Block') config.getMasterline().push(await Block.importJSON(masterline));
          }
        } else if (json.masterline.clazz === 'Block') {
          // Fallback for Old ones
          config.getMasterline().push(await Block.importJSON(json.masterline));
        }
      }
    } else if (json.clazz === 'Block') {
      // Fallback for Old ones
      config.getMasterline().push(await Block.importJSON(json));
    }
    // MarineMeister
    if (json.marineMeister && Array.isArray(json.marineMeister)) {
      for (let i = 0; i < json.marineMeister.length; i++) {
        const marineMeister = json.marineMeister[i];
        if (marineMeister.clazz === 'Block') config.getMarineMeister().push(await Block.importJSON(marineMeister));
      }
    }

    return config;
  }

  public exportJSON(): string {
    return JSON.stringify(this, (key, value) => {
      if (key.startsWith('_')) return undefined;
      return value;
    });
  }

  public getIntro() {
    return this.intro;
  }

  public setIntro(intro: string) {
    this.intro = intro;
  }

  public getRoom() {
    return this.room;
  }

  public setRoom(room: Room) {
    this.room = room;
  }

  public getFlexiChefs() {
    if (typeof this.flexiChefs === 'undefined' || this.flexiChefs == null) this.flexiChefs = [];
    return this.flexiChefs;
  }

  public removeFlexiChef(index: number) {
    if (index < this.flexiChefs.length) {
      const item = this.flexiChefs.splice(index, 1)[0];
      item.getNode().dispose();
    }
  }

  public getSpaceCombis() {
    if (typeof this.spaceCombis === 'undefined' || this.spaceCombis == null) this.spaceCombis = [];
    return this.spaceCombis;
  }

  public removeSpaceCombi(index: number) {
    if (index < this.spaceCombis.length) {
      const item = this.spaceCombis.splice(index, 1)[0];
      item.getNode().dispose();
    }
  }

  public getModular() {
    if (typeof this.modular === 'undefined' || this.modular == null) this.modular = [];
    return this.modular;
  }

  public removeModular(index: number) {
    if (index < this.modular.length) {
      const block = this.modular.splice(index, 1)[0];
      block.cleanResources();
    }
  }

  public getModularNOL() {
    if (typeof this.modularNOL === 'undefined' || this.modularNOL == null) this.modularNOL = [];
    return this.modularNOL;
  }

  public removeModularNOL(index: number) {
    if (index < this.modularNOL.length) {
      const block = this.modularNOL.splice(index, 1)[0];
      block.cleanResources();
    }
  }

  public getMasterline() {
    if (typeof this.masterline === 'undefined' || this.masterline == null) this.masterline = [];
    return this.masterline;
  }

  public removeMasterline(index: number) {
    if (index < this.masterline.length) {
      const block = this.masterline.splice(index, 1)[0];
      block.cleanResources();
    }
  }

  public getMarineMeister() {
    if (typeof this.marineMeister === 'undefined' || this.marineMeister == null) this.marineMeister = [];
    return this.marineMeister;
  }

  public removeMarineMeister(index: number) {
    if (index < this.marineMeister.length) {
      const block = this.marineMeister.splice(index, 1)[0];
      block.cleanResources();
    }
  }

  public getDiscountFlexiChef() {
    return this.discountFlexiChef;
  }

  public setDiscountFlexiChef(discountFlexiChef: number) {
    if (discountFlexiChef > 100) return;
    this.discountFlexiChef = discountFlexiChef;
  }

  public getDiscountSpaceCombi() {
    return this.discountSpaceCombi;
  }

  public setDiscountSpaceCombi(discountSpaceCombi: number) {
    if (discountSpaceCombi > 100) return;
    this.discountSpaceCombi = discountSpaceCombi;
  }

  public getDiscountModular() {
    return this.discountModular;
  }

  public setDiscountModular(discountModular: number) {
    if (discountModular > 100) return;
    this.discountModular = discountModular;
  }

  public getDiscountMasterline() {
    return this.discountMasterline;
  }

  public setDiscountMasterline(discountMasterline: number) {
    if (discountMasterline > 100) return;
    this.discountMasterline = discountMasterline;
  }

  public getSurchargeMasterline() {
    return this.surchargeMasterline;
  }

  public setSurchargeMasterline(surchargeMasterline: number) {
    this.surchargeMasterline = surchargeMasterline;
  }

  public setShowLabels(value: boolean) {
    this._showLabels = value;
    if (this.room) this.room.setShowLabels(value);
    this.flexiChefs.forEach(device => device.setShowLabels(value));
    this.spaceCombis.forEach(device => device.setShowLabels(value));
    this.modular.forEach(block => block.setShowLabels(value));
    this.modularNOL.forEach(block => block.setShowLabels(value));
    if (this.masterline) {
      if (this.masterline instanceof Block) {
        this.masterline.setShowLabels(value);
      } else {
        this.masterline.forEach(block => {
          block.setShowLabels(value);
        });
      }
    }
    this.marineMeister.forEach(block => block.setShowLabels(value));
  }

  public isShowLabels() {
    return this._showLabels;
  }

  public setShowConnections(value: boolean) {
    this._showConnections = value;
    this.modular.forEach(block => block.setShowConnections(value));
    this.modularNOL.forEach(block => block.setShowConnections(value));
    if (this.masterline) {
      if (this.masterline instanceof Block) {
        this.masterline.setShowConnections(value);
      } else {
        this.masterline.forEach(block => {
          block.setShowConnections(value);
        });
      }
    }
  }

  public isShowConnections() {
    return this._showConnections;
  }

  public isShowCutLabels() {
    return this._showCutLabels;
  }

  public setShowCutLabels(value: boolean) {
    this._showCutLabels = value;
    if (this.masterline) {
      if (this.masterline instanceof Block) {
        this.masterline.setShowCutLabels(value);
      } else {
        this.masterline.forEach(block => {
          block.setShowCutLabels(value);
        });
      }
    }
  }

  public static isFunctionItem(item: BlockItem | FreeItem) {
    let source: EnergySource | string = item.getDeviceObject().energy.source;
    if (typeof source === 'string') source = EnergySource[source];
    switch (source) {
      case EnergySource.Electro:
      case EnergySource.Gas:
      case EnergySource.Steam:
        return true;
    }
    return false;
  }
}
