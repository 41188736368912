import { Selectbox } from 'atoms';
import { EditorContext, LangContext } from 'context';
import Localizations from 'languages';
import BlockItem from 'page/Editor/configuration/BlockItem';
import { useVariantSelection } from 'page/Editor/Menu/Shared/DeviceDetailsSmall/hooks/useVariantSelection';
import { useContext } from 'react';
import { Device } from 'types';

export const VariantSelection = ({ device }: { device: Device }) => {
  const { availableVariants, isLoading, onChangeVariant, selectedVariantId } = useVariantSelection({ device });
  const { lang, langId } = useContext(LangContext);
  const { selection, selectedMasterline, menuView } = useContext(EditorContext);

  if (!availableVariants || availableVariants.length === 1) return null;

  return (
    <div className="mb-2">
      <h5 className="mb-1">{Localizations['variants'][lang]}</h5>
      <Selectbox
        disabled={isLoading}
        state={selectedVariantId}
        setState={onChangeVariant}
        options={availableVariants.map(d => ({
          disabled:
            menuView === 'masterline' &&
            selection instanceof BlockItem &&
            (d?.model?.width ?? 0) - (device?.model?.width ?? 0) > selectedMasterline.getWidth() - selection.getBlockRow().getWidth(),
          label: d?.translations[langId]?.description,
          value: d?.id
        }))}
      />
    </div>
  );
};
