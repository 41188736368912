import * as React from 'react';
import * as BABYLON from 'babylonjs';
import 'babylonjs-serializers';
import BabylonScene, { SceneEventArgs, SceneMountReturn } from '../../components/babylon/BabylonScene';
import 'pepjs';

// Components:
import LabelUtils from '../../components/babylon/util/LabelUtils';
import LoaderUtils from '../../components/babylon/util/LoaderUtils';
import MarkUtils from '../../components/babylon/util/MarkUtils';
import MaterialUtils from 'components/babylon/util/MaterialUtils';
import DeviceNode, { PropertyType as DNPT } from '../../components/babylon/node/DeviceNode';
import BlockNode from 'components/babylon/node/BlockNode';
import { Device } from 'types/Device';

// Styles
import './Scene.scss';
import BlockGroupNode from 'components/babylon/node/BlockGroupNode';
import BlockBoardNode from 'components/babylon/node/BlockBoardNode';
import Block from './configuration/Block';
import BlockItem from './configuration/BlockItem';
import BlockGroup from './configuration/BlockGroup';
import BlockBoard from './configuration/BlockBoard';
import BlockRow from './configuration/BlockRow';
import { WallClipping, InnerRoom, RoomChild } from './configuration/Room';
import WallClippingNode from 'components/babylon/node/helper/WallClippingNode';
import InnerRoomNode from 'components/babylon/node/helper/InnerRoomNode';
import Vector2 from './configuration/Vector2';
import FreeItem from './configuration/FreeItem';
import Vector3 from './configuration/Vector3';
import Configuration from './configuration/Configuration';
import Dimensions from 'utils/Dimensions';
import { isIOS } from 'utils/isIOS';
import { STLExport } from 'babylonjs-serializers';
import JSZip from 'jszip';
import BasicUtils from 'components/babylon/util/BasicUtils';
import { saveAs } from 'save-as';
import { EquipmentHelper } from 'page/Editor/configuration/Equipment';
import { addHygiene } from 'utils/addDevice';

export type ScreenshotData = {
  top?: string;
  viewA?: string;
  viewB?: string;
};

export type OnEvent = {
  type: 'select' | 'deselect' | 'grab' | 'rotate' | 'error';
  data?: Selected;
};

export type SceneProps = {
  viewOnly?: boolean;
};

export type ViewName = 'Free' | 'Top' | 'FreeFront' | 'FreeBack' | 'FreeDiagonal' | 'Front' | 'Back' | 'Diagonal' | 'Top2';
export type Selected = Block | BlockItem | BlockGroup | BlockBoard | WallClipping | InnerRoom | RoomChild | FreeItem;

// ===================================================================
export default class Scene extends React.Component<SceneProps, {}> {
  public static CURRENT_SCENE: BABYLON.Scene;
  private _scene: BABYLON.Scene;
  private _initialized = false;
  private _babylonScene = React.createRef<BabylonScene>();
  private _onEvents = new Array<(evt: OnEvent) => void>();

  private _selected: Selected;
  private _substructureMode = false;
  private _upperStructureMode = false;
  private _mergeMode = false;
  private _view: ViewName = 'Top';
  private _viewChangeEvents = new Array<(view: ViewName, camera: BABYLON.Camera) => void>();

  private _blockMode = false;

  public zoomIn: () => void;
  public zoomOut: () => void;
  public zoomAuto: () => void;

  public setView: (view: ViewName) => void;

  public resize = () => {
    this._babylonScene.current.resize();
  };

  public screenshot: (focus: Block | FreeItem, success: (data: ScreenshotData) => void, quallity: number) => void;

  public focusCamera: (center: Block) => void;

  public pushOnEvent(event: (evt: OnEvent) => void) {
    this._onEvents.push(event);
  }

  private triggerOnEvent(evt: OnEvent) {
    for (let i = 0; i < this._onEvents.length; i++) {
      const event = this._onEvents[i];
      setTimeout(() => {
        event(evt);
      }, 0);
    }
  }

  public onDragOver: (device: Device | RoomChild, block?: Block, configuration?: Configuration) => void;
  public onDragOut: () => void;

  public isInitialized = () => {
    return this._initialized;
  };

  public getSelected() {
    return this._selected;
  }

  public setSelected(selected: Selected) {
    // Clean Old Selection
    if (this._selected) {
      if (this._selected instanceof Block) {
        this._selected.getNode().setShowGrab(false);
      } else if (this._selected instanceof BlockItem) {
        this._selected.getNode().set(DNPT.Mark, false);
      } else if (this._selected instanceof BlockGroup) {
        this._selected.getNode().set(DNPT.Mark, false);
      } else if (this._selected instanceof BlockBoard) {
        this._selected.getNode().setEnabledMark(false);
      } else if (this._selected instanceof WallClipping) {
        this._selected.getNode().setEnabledMark(false);
      } else if (this._selected instanceof InnerRoom) {
        this._selected.getNode().setEnabledMark(false);
      } else if (this._selected instanceof RoomChild) {
        this._selected.getNode().setEnabledMark(false);
      } else if (this._selected instanceof FreeItem) {
        this._selected.getNode().set(DNPT.Mark, false);
      }
    }
    // Turn off Merge Mode if on
    if (this._mergeMode) this.setMergeMode(false);
    // Update Selection
    this._selected = selected;
    // Prepare new Selection
    if (this._selected) {
      if (this._selected instanceof Block) {
        this._selected.getNode().setShowGrab(true);
      } else if (this._selected instanceof BlockItem) {
        this._selected.getNode().set(DNPT.Mark, true);
      } else if (this._selected instanceof BlockGroup) {
        this._selected.getNode().set(DNPT.Mark, true);
      } else if (this._selected instanceof BlockBoard) {
        this._selected.getNode().setEnabledMark(true);
      } else if (this._selected instanceof WallClipping) {
        this._selected.getNode().setEnabledMark(true);
      } else if (this._selected instanceof InnerRoom) {
        this._selected.getNode().setEnabledMark(true);
      } else if (this._selected instanceof RoomChild) {
        this._selected.getNode().setEnabledMark(true);
      } else if (this._selected instanceof FreeItem) {
        this._selected.getNode().set(DNPT.Mark, true);
      }
    }
    console.log('Selected', this._selected);
    window['selected'] = this._selected;
  }

  public setMergeMode: (value: boolean) => void;

  public getMergeMode() {
    return this._mergeMode;
  }

  public setSubstructureMode(value: boolean) {
    this._substructureMode = value;
  }

  public getSubstructureMode() {
    return this._substructureMode;
  }

  public setUpperStructureMode(value: boolean) {
    this._upperStructureMode = value;
  }

  public getUpperStructureMode() {
    return this._upperStructureMode;
  }

  // public setShowLabels(value: boolean, item?: Block) {
  //   if (item) {
  //     item.setShowLabels(value);
  //   } else {
  //     const blocks = this._scene.getTransformNodesByID('block');
  //     for (let i = 0; i < blocks.length; i++) {
  //       const block = blocks[i];
  //       if (block instanceof BlockNode) block.getBlock().setShowLabels(value);
  //     }
  //   }
  // }

  // public setShowConnections(value: boolean, item?: Block) {
  //   if (item) {
  //     item.setShowConnections(value);
  //   } else {
  //     const blocks = this._scene.getTransformNodesByID('block');
  //     for (let i = 0; i < blocks.length; i++) {
  //       const block = blocks[i];
  //       if (block instanceof BlockNode) block.getBlock().setShowConnections(value);
  //     }
  //   }
  // }

  public getMasterlineBlock() {
    const blocks = this._scene.getTransformNodesByID('block');
    for (let i = 0; i < blocks.length; i++) {
      const block = blocks[i];
      if (block instanceof BlockNode && block.getBlock().isMasterline()) return block;
    }
    return null;
  }

  public isBlockMode() {
    return this._blockMode;
  }

  public setBlockMode(value: boolean) {
    this._blockMode = value;
  }

  public startSTLDownload() {
    const notLike = (name: string, mesh: BABYLON.Mesh) => {
      return !mesh.name.startsWith(name) && !mesh.parent.name.startsWith(name);
    };

    const hasParent = (name: string, mesh: BABYLON.Node): boolean => {
      if (mesh.parent) {
        if (mesh.parent.name === name) return true;
        else return hasParent(name, mesh.parent);
      }
      return false;
    };

    console.log('Start Download');

    if (window.confirm('Bitte stellen Sie sicher das die Konfiguration gespeichert wurde, die Seite wird im anschluss an den Download neu geladen.')) {
      const zip = new JSZip();

      let entry = 0;
      const allMeshes: BABYLON.Mesh[] = [];

      this._scene.getNodes().forEach(n => {
        if (n.id.startsWith('block')) {
          const meshes: BABYLON.Mesh[] = [];

          (n as BlockNode).scaling = new BABYLON.Vector3(10, 10, 10);

          BasicUtils.computeAllWorldMatrix(n as BlockNode);

          n.getChildren(undefined, false).forEach(c => {
            if (c instanceof BABYLON.Mesh) {
              if (c.parent.name === 'output') {
                meshes.push(c);
              }
              // else if (hasParent('PlumbingWall', c)) {
              //   if (!hasParent('Right', c)) meshes.push(c);
              // }

              // if (notLike('label', c) && notLike('grab', c) && notLike('mark', c) && notLike('dropMark', c)) {

              //   meshes.push(c);

              // }
            }
          });

          allMeshes.push(...meshes);

          const stl = STLExport.CreateSTL(meshes, false, 'export', false, false, false);

          zip.file(`${++entry}_${(n as BlockNode).getBlock().getBlockType()}.stl`, stl);
        }
      });

      console.log('Download for', entry, 'Blocks');

      if (entry > 0) {
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          saveAs(content, '3d_export.zip');

          window.location.reload();
        });
      }

      // STLExport.CreateSTL(meshes, true, 'export', false, false, false);

      // window.location.reload();
    }
  }

  private onSceneMount = (e: SceneEventArgs) => {
    const sceneMountReturn: SceneMountReturn = {
      resizeEvents: new Array<() => void>()
    };
    const { canvas, scene, engine } = e;
    this._scene = scene;

    scene['registerOnViewChange'] = (f: (view: ViewName, camera: BABYLON.Camera) => void) => {
      this._viewChangeEvents.push(f);
    };

    scene['unregisterOnViewChange'] = (f: (view: ViewName, camera: BABYLON.Camera) => void) => {
      const index = this._viewChangeEvents.indexOf(f);
      if (index >= 0) this._viewChangeEvents.splice(index, 1);
    };

    scene['getView'] = () => {
      return this._view;
    };

    Scene.CURRENT_SCENE = scene;

    //#region Scene
    // Set scene properties
    const sceneColor: number = 0.9;
    scene.clearColor = new BABYLON.Color4(sceneColor, sceneColor, sceneColor, 1);
    scene.ambientColor = new BABYLON.Color3(0.1, 0.1, 0.1);

    scene.fogMode = BABYLON.Scene.FOGMODE_LINEAR;
    scene.fogColor = new BABYLON.Color3(sceneColor, sceneColor, sceneColor);
    scene.fogStart = 4000;
    scene.fogEnd = 5000;

    // scene.animationsEnabled = false;
    // scene.collisionsEnabled = false;
    // scene.fogEnabled = true;
    // scene.lensFlaresEnabled = false;
    // scene.lightsEnabled = true;
    // scene.particlesEnabled = false;
    // scene.postProcessesEnabled = false;
    // scene.probesEnabled = false;
    // scene.texturesEnabled = true;
    // scene.proceduralTexturesEnabled = true;
    // scene.renderTargetsEnabled = true;
    scene.shadowsEnabled = true;
    // scene.skeletonsEnabled = false;
    // scene.spritesEnabled = false;
    //#endregion

    //#region Cameras
    let cameraDistance = 800;
    const initialCameraDistance = cameraDistance + 300;

    // Main Camera
    const camera = new BABYLON.ArcRotateCamera('Camera', 0, 0, 10, new BABYLON.Vector3(0, 0, 0), scene);
    camera.setPosition(new BABYLON.Vector3(0, 150, -cameraDistance));

    camera.lowerBetaLimit = 0.01;
    camera.upperBetaLimit = (Math.PI / 2) * 0.9;
    camera.lowerRadiusLimit = cameraDistance - 400;

    camera.cameraRotation = new BABYLON.Vector2(Math.PI, 0);
    camera.layerMask = 0x0fffffff | 0x20000000;

    camera.panningSensibility = 100;
    camera.panningAxis = new BABYLON.Vector3(1, 0, 1);

    // Top Camera for Plan / Export
    const cameraTop = new BABYLON.ArcRotateCamera('CameraTop', 0, 0, 10, new BABYLON.Vector3(0, 0, 0), scene);
    cameraTop.setPosition(new BABYLON.Vector3(0, cameraDistance, -0.000000001));
    cameraTop.mode = BABYLON.Camera.ORTHOGRAPHIC_CAMERA;

    const cameraTopResize = () => {
      const zoom = (initialCameraDistance / cameraTop.radius) * 2;
      const height = canvas.offsetHeight / zoom;
      const width = canvas.offsetWidth / zoom;
      cameraTop.orthoTop = height;
      cameraTop.orthoBottom = -height;
      cameraTop.orthoLeft = -width;
      cameraTop.orthoRight = width;
    };
    cameraTopResize();
    sceneMountReturn.resizeEvents.push(() => {
      if (scene.activeCamera === cameraTop) {
        cameraTopResize();
      }
    });
    cameraTop.layerMask = 0x0fffffff | 0x20000000;

    // Top Camera for 3D Scene
    const cameraTop2 = new BABYLON.ArcRotateCamera('CameraTop2', 0, 0, 10, new BABYLON.Vector3(0, 0, 0), scene);
    cameraTop2.setPosition(new BABYLON.Vector3(0, cameraDistance, -0.000000001));
    cameraTop2.layerMask = 0x0fffffff | 0x20000000;

    // Front Camera for Export
    const cameraFront = new BABYLON.ArcRotateCamera('CameraFront', 0, 0, 10, new BABYLON.Vector3(0, 0, 0), scene);
    cameraFront.setPosition(new BABYLON.Vector3(0, 150, -cameraDistance));
    cameraFront.layerMask = 0x0fffffff | 0x20000000;

    // Back Camera for Export
    const cameraBack = new BABYLON.ArcRotateCamera('CameraBack', 0, 0, 10, new BABYLON.Vector3(0, 0, 0), scene);
    cameraBack.setPosition(new BABYLON.Vector3(0, 150, cameraDistance));
    cameraBack.cameraRotation = new BABYLON.Vector2(0, 0);
    cameraBack.layerMask = 0x0fffffff | 0x20000000;

    // Diagonal Camera for Export
    const cameraDiagonal = new BABYLON.ArcRotateCamera('CameraDiagonal', 0, 0, 10, new BABYLON.Vector3(0, 0, 0), scene);
    cameraDiagonal.setPosition(new BABYLON.Vector3(-cameraDistance / 2, cameraDistance / 2, -cameraDistance));
    cameraDiagonal.layerMask = 0x0fffffff | 0x20000000;

    // Diagonal Back Camera for Export
    const cameraDiagonalBack = new BABYLON.ArcRotateCamera('CameraDiagonalBack', 0, 0, 10, new BABYLON.Vector3(0, 0, 0), scene);
    cameraDiagonalBack.setPosition(new BABYLON.Vector3(cameraDistance / 2, cameraDistance / 2, cameraDistance));
    cameraDiagonalBack.layerMask = 0x0fffffff | 0x20000000;

    scene.activeCamera = cameraTop;
    //#endregion

    //#region Lights
    // Main Light
    const light = new BABYLON.DirectionalLight('directional', new BABYLON.Vector3(-1, -1, 1), scene);
    light.intensity = 1;
    light.position = new BABYLON.Vector3(1000, 200, -1400);
    //light.lightmapMode = BABYLON.Light.LIGHTMAP_SHADOWSONLY;

    // Back Light
    const lightShadow = new BABYLON.DirectionalLight('directional-shadow', new BABYLON.Vector3(-1, -1.5, 1), scene);
    lightShadow.intensity = 5;
    lightShadow.position = new BABYLON.Vector3(1000, 200, -1400);
    lightShadow.lightmapMode = BABYLON.Light.LIGHTMAP_SHADOWSONLY;
    window['light'] = lightShadow;

    // Back Light
    const lightBack = new BABYLON.DirectionalLight('directional-back', new BABYLON.Vector3(1, -1, -1), scene);
    lightBack.intensity = 0.66;
    lightBack.position = new BABYLON.Vector3(-1000, 200, 1400);
    lightBack.setEnabled(false);
    //#endregion

    // Add ground
    const ground = BABYLON.Mesh.CreateGround('ground', 10000, 10000, 1, scene, false);
    ground.material = new BABYLON.StandardMaterial('ground', scene);
    (ground.material as BABYLON.StandardMaterial).specularColor = BABYLON.Color3.Black();
    (ground.material as BABYLON.StandardMaterial).diffuseColor = BABYLON.Color3.White();
    (ground.material as BABYLON.StandardMaterial).ambientColor = BABYLON.Color3.White();
    const groundGrid = new BABYLON.Texture('texture/grid.png', scene);
    groundGrid.uScale = 100;
    groundGrid.vScale = 100;
    const groundMirrorTexture = new BABYLON.MirrorTexture('mirror', 1024, scene, true);
    groundMirrorTexture.mirrorPlane = new BABYLON.Plane(0, -1, 0, 0.1);
    groundMirrorTexture.renderList = [];
    groundMirrorTexture.level = 0.25;
    const groundMirrorTextureEmpty = new BABYLON.MirrorTexture('mirror-empty', 1024, scene, true);
    groundMirrorTextureEmpty.mirrorPlane = new BABYLON.Plane(0, -1, 0, 0.1);
    groundMirrorTextureEmpty.renderList = [];
    groundMirrorTextureEmpty.level = 0.25;
    (ground.material as BABYLON.StandardMaterial).reflectionTexture = groundMirrorTextureEmpty;
    ground.receiveShadows = true;
    ground.isPickable = false;
    // light.excludedMeshes.push(ground);
    lightShadow.excludedMeshes.push(ground);
    lightBack.excludedMeshes.push(ground);

    // Add shadows
    const shadowGenerator = new BABYLON.ShadowGenerator(1024 * 1, lightShadow, true);
    shadowGenerator.useBlurExponentialShadowMap = true;
    shadowGenerator.blurScale = 1;
    shadowGenerator.usePoissonSampling = true;
    window['shadowGenerator'] = shadowGenerator;
    // shadowGenerator.usePercentageCloserFiltering = true;

    //#region Prepare Utils
    // Prepare MaterialUtils
    MaterialUtils.initialize();
    // Prepare MarkUtils
    MarkUtils.initialize();
    // Prepare LabelUtils
    LabelUtils.initialize();
    // Prepare LoaderUtils
    LoaderUtils.initialize();
    // Prepare DeviceNode
    DeviceNode.defaultSettings.shadowGenerators = [shadowGenerator];
    DeviceNode.defaultSettings.mirrors = [groundMirrorTexture];
    DeviceNode.defaultSettings.modelNode = new BABYLON.TransformNode('models', scene);
    DeviceNode.defaultSettings.modelNode.setEnabled(false);
    // Prepare BlockNode
    BlockNode.initialize();
    //#endregion

    this._initialized = true;

    //#region Chrome Fix
    // TODO Fix Fix
    const runChromeFix = (callback?: () => void) => {
      if (navigator && /.*(Chrome).*/.test(navigator.userAgent)) {
        // "Fix" first image not generated Bug
        setTimeout(() => {
          try {
            BABYLON.Tools.CreateScreenshotUsingRenderTarget(
              engine,
              camera,
              { width: 100, height: 100 },
              (_data: string) => {
                // Do Nothing
                if (callback) callback();
              },
              'image/png',
              1,
              false
            );
          } catch (e) {
            setTimeout(() => {
              runChromeFix();
            }, 100);
          }
        }, 500);
      } else {
        if (callback) callback();
      }
    };
    //#endregion

    const renderFirstTime = () => {
      try {
        scene.render();
        // runChromeFix();
      } catch (e) {
        setTimeout(() => {
          renderFirstTime();
        }, 100);
      }
    };
    renderFirstTime();

    //#region RenderLoop
    engine.runRenderLoop(() => {
      try {
        if (scene && document.hasFocus()) {
          scene.render();
        }
      } catch (e) {
        console.error(e);
      }
    });
    //#endregion

    //#region Events
    let startingPoint: BABYLON.Vector3;
    let currentMeshStartingPoint: BABYLON.Vector3;
    let currentMesh: BABYLON.TransformNode = null;
    let currentMeshType: 'block' | 'device' | 'group' | 'grab' | 'board' | 'wallmounted' | 'innerroom';
    let moveIntervalTrigger = false;
    let moveInterval: NodeJS.Timeout = null;
    let pickupDistance = 20;

    const getGroundPosition = (move?: boolean) => {
      // Use a predicate to get position on the ground
      const pickinfo = scene.pick(
        scene.pointerX,
        scene.pointerY,
        move
          ? mesh => {
              return mesh === ground;
            }
          : mesh => {
              return mesh && mesh.isEnabled();
            }
      );
      if (pickinfo.hit) {
        return pickinfo.pickedPoint;
      }

      return null;
    };

    const onPointerDown = (evt: PointerEvent) => {
      if (evt.button !== 0) {
        return;
      }

      // check if we are under a mesh
      const pickInfo = scene.pick(scene.pointerX, scene.pointerY, mesh => {
        return mesh !== ground && mesh.isEnabled() && mesh.isPickable;
      });
      // console.log(pickInfo);
      if (pickInfo.hit) {
        currentMeshType = null;
        currentMesh = pickInfo.pickedMesh;
        while (currentMesh != null) {
          // console.log('Mesh?', currentMesh);
          // +++ Block +++
          if (!this._blockMode) {
            if (currentMesh instanceof DeviceNode) {
              if (currentMesh.getBlockItem()) {
                const item = currentMesh.getBlockItem();
                if (this._substructureMode && this._mergeMode) {
                  const currentSelected = this.getSelected();
                  if (currentSelected instanceof BlockItem || currentSelected instanceof BlockGroup) {
                    currentSelected.mergeWith(item);
                    if (currentSelected instanceof BlockItem && currentSelected.getParent() instanceof BlockGroup) {
                      this.setSelected(currentSelected.getParent() as BlockGroup);
                      this.setMergeMode(true);
                    }
                    // Trigger Event
                    this.triggerOnEvent({
                      type: 'select',
                      data: this.getSelected()
                    });
                    updateMergeMode();
                  }
                  return;
                } else if (this._upperStructureMode) {
                  currentMeshType = 'device';
                  // Trigger Event
                  this.triggerOnEvent({
                    type: 'select',
                    data: item
                  });
                  this.setSelected(item);
                } else if (item.getParent() instanceof BlockGroup) {
                  const group = item.getParent() as BlockGroup;
                  currentMesh = group.getNode();
                  currentMeshType = 'group';
                  // Trigger Event
                  this.triggerOnEvent({
                    type: 'select',
                    data: group
                  });
                  this.setSelected(group);
                } else {
                  currentMeshType = 'device';
                  // Trigger Event
                  this.triggerOnEvent({
                    type: 'select',
                    data: item
                  });
                  this.setSelected(item);
                }
              } else if (currentMesh.getFreeItem()) {
                const item = currentMesh.getFreeItem();
                // TODO
                currentMeshType = 'device';
                // Trigger Event
                this.triggerOnEvent({
                  type: 'select',
                  data: item
                });
                this.setSelected(item);
              }
              break;
            } else if (currentMesh instanceof BlockGroupNode) {
              const group = currentMesh.getBlockGroup();
              currentMeshType = 'group';
              // Trigger Event
              this.triggerOnEvent({
                type: 'select',
                data: group
              });
              this.setSelected(group);
              break;
            } else if (currentMesh instanceof BlockBoardNode) {
              const board = currentMesh.getBlockBoard();
              currentMeshType = 'board';
              // Trigger Event
              this.triggerOnEvent({
                type: 'select',
                data: board
              });
              this.setSelected(board);
              break;
            }
          } else {
            if (currentMesh instanceof BlockNode) {
              currentMeshType = 'block';
              // Trigger Event
              this.triggerOnEvent({
                type: 'select',
                data: currentMesh.getBlock()
              });
              this.setSelected(currentMesh.getBlock());
              break;
            }
          }
          // +++ Room +++
          if (currentMesh.name.startsWith('grab.')) {
            currentMeshType = 'grab';
            break;
          } else if (currentMesh instanceof WallClippingNode) {
            currentMeshType = 'wallmounted';
            // Trigger Event
            this.triggerOnEvent({
              type: 'select',
              data: currentMesh.getWallClipping()
            });
            this.setSelected(currentMesh.getWallClipping());
            break;
          } else if (currentMesh instanceof InnerRoomNode) {
            currentMeshType = 'innerroom';
            // Trigger Event
            this.triggerOnEvent({
              type: 'select',
              data: currentMesh.getInnerRoom()
            });
            this.setSelected(currentMesh.getInnerRoom());
            break;
          }
          currentMesh = currentMesh.parent as BABYLON.TransformNode;
        }
        if (!currentMeshType) {
          currentMesh = null;
        } else {
          currentMeshStartingPoint = currentMesh.getAbsolutePosition().clone();
          startingPoint = getGroundPosition();

          if (startingPoint) {
            // we need to disconnect camera from canvas
            if (scene.activeCamera === camera)
              setTimeout(() => {
                camera.detachControl(canvas);
              }, 0);
          }

          if (!(currentMeshType === 'device' || currentMeshType === 'group')) this.setMergeMode(false);

          if (currentMeshType === 'device' || currentMeshType === 'group') startMoveInterval();

          // console.log('picked Mesh', currentMesh);
        }
      }

      if (currentMeshType == null) {
        // Trigger Event
        this.triggerOnEvent({
          type: 'deselect',
          data: null
        });
        this.setSelected(null);
      }
    };

    const startMoveInterval = () => {
      moveInterval = setInterval(() => {
        if (moveIntervalTrigger && currentMesh) {
          if (currentMesh instanceof DeviceNode) {
            const item = currentMesh.getBlockItem();
            if (item && item.getParent() instanceof BlockRow) {
              let rowObject = item.getParent() as BlockRow;
              rowObject.getParent().moveItem(item);
            }
          } else if (currentMesh instanceof BlockGroupNode) {
            const group = currentMesh.getBlockGroup();
            let rowObject = group.getParent();
            rowObject.getParent().moveItem(group);
          }
          moveIntervalTrigger = false;
        }
      }, 250);
    };

    const onPointerUp = () => {
      if (startingPoint) {
        if (moveInterval) {
          clearInterval(moveInterval);
        }

        switch (currentMeshType) {
          case 'device':
            if (currentMesh instanceof DeviceNode) {
              if (currentMesh.getBlockItem()) {
                currentMesh.position.y = 0;
                const item = currentMesh.getBlockItem();
                if (item.getParent() instanceof BlockRow) {
                  let rowObject = item.getParent() as BlockRow;
                  rowObject.getParent().dropItem(item);
                  item.getNode().set(DNPT.Mark, true);
                  currentMesh = item.getNode();
                }
              }
            }
            break;
          case 'group':
            currentMesh.position.y = 0;
            if (currentMesh instanceof BlockGroupNode) {
              if (currentMesh.getBlockGroup()) {
                const group = currentMesh.getBlockGroup();
                let rowObject = group.getParent();
                rowObject.getParent().dropItem(group);
                group.getNode().set(DNPT.Mark, true);
                currentMesh = group.getNode();
              }
            }
            break;
          case 'block':
            currentMesh.position.y = 0;
            if (currentMesh instanceof BlockNode) {
              currentMesh.setPosition({
                x: currentMesh.position.x,
                z: currentMesh.position.z,
                y: 0
              });
            }
            break;

          case 'grab':
            let p = currentMesh.parent;
            while (p != null) {
              if (p instanceof BlockNode) {
                this.triggerOnEvent({
                  type: 'grab',
                  data: p.getBlock()
                });
                const blockBoardNode = p.getBlockBoardNode();
                if (blockBoardNode) blockBoardNode.bake();
                break;
              }
              p = p.parent;
            }
            break;
          case 'wallmounted':
            if (currentMesh instanceof WallClippingNode) {
              currentMesh.getWallClipping().getRoom().getNode().bake();
            }
            break;
          case 'innerroom':
            if (currentMesh instanceof InnerRoomNode) {
              currentMesh.getInnerRoom().getRoom().getNode().updateLabels();
            }
            break;
        }
        if (scene.activeCamera === camera)
          setTimeout(() => {
            camera.attachControl(canvas, true);
          }, 0);
        startingPoint = null;
        currentMeshStartingPoint = null;
        currentMeshType = null;
        currentMesh = null;
        return;
      }
    };

    let _lastPointerMove: PointerEvent = null;
    const onPointerMove = (evt: PointerEvent) => {
      if (_lastPointerMove != null && _lastPointerMove.x === evt.x && _lastPointerMove.y === evt.y) return;
      // console.log('move', evt);
      _lastPointerMove = evt;

      if (!startingPoint) {
        return;
      }

      let current = getGroundPosition(true);
      // console.log('current', current);

      if (!current) {
        return;
      }

      moveIntervalTrigger = true;

      switch (currentMeshType) {
        case 'device':
        case 'block':
        case 'group':
          {
            if (
              this._upperStructureMode ||
              this._substructureMode ||
              (currentMesh instanceof DeviceNode && currentMesh.getBlockItem() && currentMesh.getBlockItem().getParent() instanceof BlockGroup)
            )
              return;
            let diff = current.subtract(startingPoint);
            // currentMesh.position.addInPlace(diff);
            // startingPoint = current;

            let modX = diff.x % Dimensions.GRID;
            let modZ = diff.z % Dimensions.GRID;
            const x = Dimensions.inGrid(currentMeshStartingPoint.x + diff.x + (modX > Dimensions.GRID / 2 ? Dimensions.GRID - modX : -modX));
            const z = Dimensions.inGrid(currentMeshStartingPoint.z + diff.z + (modZ > Dimensions.GRID / 2 ? Dimensions.GRID - modZ : -modZ));
            currentMesh.setAbsolutePosition(new BABYLON.Vector3(x, pickupDistance, z));
            if (this._selected instanceof Block) {
              this._selected.setPosition(new Vector3(x, 0, z));
            } else if (this._selected instanceof FreeItem) {
              this._selected.setPosition(new Vector3(x, this._selected.getPosition().y, z));
            }
          }
          break;
        case 'grab':
          {
            let diff = current.subtract(startingPoint);
            const x = currentMeshStartingPoint.x + diff.x;
            const z = currentMeshStartingPoint.z + diff.z;
            currentMesh.setAbsolutePosition(new BABYLON.Vector3(x, currentMesh.getAbsolutePosition().y, z));
            currentMesh.position.z = BlockNode.defaultSettings.grab.offset;

            let p = currentMesh.parent;
            while (p != null) {
              if (p instanceof BlockNode) {
                p.checkGrab();
                break;
              }
              p = p.parent;
            }
          }
          break;
        case 'wallmounted':
          if (currentMesh instanceof WallClippingNode) {
            let modX = current.x % Dimensions.GRID;
            let modZ = current.z % Dimensions.GRID;
            const x = current.x + (modX > Dimensions.GRID / 2 ? Dimensions.GRID - modX : -modX);
            const z = current.z + (modZ > Dimensions.GRID / 2 ? Dimensions.GRID - modZ : -modZ);

            const newPos = currentMesh.getWallClipping().getRoom().getNode().guessWallMountedPosition({ x, y: z }, currentMesh.scaling.x);

            currentMesh.getWallClipping().setWall(newPos.wall);
            currentMesh.getWallClipping().setPosition(newPos.x);
          }
          break;
        case 'innerroom':
          if (currentMesh instanceof InnerRoomNode) {
            let modX = current.x % Dimensions.GRID;
            let modZ = current.z % Dimensions.GRID;
            const x = current.x + (modX > Dimensions.GRID / 2 ? Dimensions.GRID - modX : -modX);
            const z = current.z + (modZ > Dimensions.GRID / 2 ? Dimensions.GRID - modZ : -modZ);

            const newPos = currentMesh
              .getInnerRoom()
              .getRoom()
              .getNode()
              .guessInnerRoomPosition(
                { x, y: z },
                currentMesh.getInnerRoom().getRotation(),
                Dimensions.CM(currentMesh.getInnerRoom().getWidth()),
                Dimensions.CM(currentMesh.getInnerRoom().getDepth())
              );

            currentMesh.getInnerRoom().setPosition(new Vector2(newPos.x, newPos.y));
          }
          break;

        default:
          break;
      }
    };

    const onContextMenu = (evt: MouseEvent) => {
      evt.preventDefault();
      // console.log('onContextMenu', evt);
      if (!currentMesh || !startingPoint) return;

      if (currentMesh instanceof BlockNode) {
        const rotation = currentMesh.getBlock().getRotation();
        rotation.y += (Math.PI / 2) % (2 * Math.PI);
        currentMesh.getBlock().setRotation(rotation);
        this.triggerOnEvent({
          type: 'rotate',
          data: currentMesh.getBlock()
        });
      } else if (currentMesh instanceof DeviceNode) {
        if (currentMesh.getFreeItem()) {
          const rotation = currentMesh.getFreeItem().getRotation();
          rotation.y += (Math.PI / 2) % (2 * Math.PI);
          currentMesh.getFreeItem().setRotation(rotation);
          this.triggerOnEvent({
            type: 'rotate',
            data: currentMesh.getFreeItem()
          });
        }
      } else if (currentMesh instanceof InnerRoomNode) {
        let rotation = currentMesh.getInnerRoom().getRotation();
        rotation += (Math.PI / 2) % (2 * Math.PI);
        currentMesh.getInnerRoom().setRotation(rotation);
        this.triggerOnEvent({
          type: 'rotate',
          data: currentMesh.getInnerRoom()
        });
      }
    };

    if (!this.props.viewOnly) {
      canvas.addEventListener('pointerdown', onPointerDown, false);
      canvas.addEventListener('pointerup', onPointerUp, false);
      canvas.addEventListener('pointermove', onPointerMove, false);
      canvas.addEventListener('contextmenu', onContextMenu, false);
    }

    let zoomAuto = true;
    this.zoomIn = () => {
      if (scene.activeCamera === camera) {
        camera.radius = Math.max(cameraDistance - 400, camera.radius - 100);
      } else if (scene.activeCamera === cameraTop) {
        cameraTop.radius = Math.max(cameraDistance - 400, cameraTop.radius - 100);
        cameraTopResize();
      }
      zoomAuto = false;
    };

    this.zoomOut = () => {
      if (scene.activeCamera === camera) {
        camera.radius = camera.radius + 100;
      } else if (scene.activeCamera === cameraTop) {
        cameraTop.radius = cameraTop.radius + 100;
        cameraTopResize();
      }
      zoomAuto = false;
    };

    this.zoomAuto = (center?: Block) => {
      zoomAuto = true;
      // TODO ... something to do stuff ... whatever ...
      this.focusCamera(center);

      camera.radius = cameraDistance;

      cameraTop.radius = cameraDistance;
      cameraTopResize();
    };

    this.setView = view => {
      this._view = view;
      setTimeout(() => {
        try {
          scene.activeCamera.detachControl(canvas);
        } catch (e) {}
      }, 0);
      switch (view) {
        case 'Free':
          scene.activeCamera = camera;
          setTimeout(() => {
            camera.attachControl(canvas, true);
          }, 0);
          camera.radius = cameraDistance;
          (ground.material as BABYLON.StandardMaterial).diffuseTexture = undefined;
          break;
        case 'Top':
          scene.activeCamera = cameraTop;
          cameraTopResize();
          (ground.material as BABYLON.StandardMaterial).diffuseTexture = groundGrid;
          break;
        case 'FreeFront':
          scene.activeCamera = camera;
          setTimeout(() => {
            camera.attachControl(canvas, true);
          }, 0);
          camera.radius = cameraDistance;
          camera.setPosition(new BABYLON.Vector3(0, 150, -cameraDistance));
          break;
        case 'FreeBack':
          scene.activeCamera = camera;
          setTimeout(() => {
            camera.attachControl(canvas, true);
          }, 0);
          camera.radius = cameraDistance;
          camera.setPosition(new BABYLON.Vector3(0, 150, cameraDistance));
          camera.cameraRotation = new BABYLON.Vector2(0, 0);
          break;
        case 'FreeDiagonal':
          scene.activeCamera = camera;
          setTimeout(() => {
            camera.attachControl(canvas, true);
          }, 0);
          camera.radius = cameraDistance;
          camera.setPosition(new BABYLON.Vector3(-cameraDistance / 2, cameraDistance / 2, -cameraDistance));
          break;
        case 'Front':
          scene.activeCamera = cameraFront;
          break;
        case 'Back':
          scene.activeCamera = cameraBack;
          break;
        case 'Diagonal':
          scene.activeCamera = cameraDiagonal;
          break;
        case 'Top2':
          scene.activeCamera = cameraTop2;
          break;
      }

      for (let i = 0; i < this._viewChangeEvents.length; i++) {
        const event = this._viewChangeEvents[i];
        event(view, scene.activeCamera);
      }
    };

    if (this.props.viewOnly) this.setView('Free');

    this.screenshot = async (focus: Block | FreeItem, success: (data: ScreenshotData) => void, quallity: 2) => {
      let labelOffsetTop = 0;
      let labelOffsetDiagonal = 0;
      const focusPosition = focus.getPosition().get();
      focusPosition.y = 0;
      let marked = false;
      if (focus instanceof Block) {
        if (focus.getNode().isEnabled()) {
          marked = true;
          focus.getNode().getMark().setEnabled(false);
          focus.getNode().setShowGrab(false);
        }
        if (focus.isShowLabels()) {
          labelOffsetTop = 320;
          labelOffsetDiagonal = 60;
        }
        if (focus.getRotation().y < Math.PI * 0.25 || focus.getRotation().y >= Math.PI * 1.75) {
          // 0°
          if (focus.getType() === 'Single') focusPosition.z -= focus.getRowBottom().getDepth() / 20;
          focusPosition.z -= focus.getDepthExtension() / 20;
          if (focus.isInstallationWall()) focusPosition.z -= focus.getInstallationWallDepth() / 20;
        } else if (focus.getRotation().y >= Math.PI * 0.25 && focus.getRotation().y < Math.PI * 0.75) {
          // 90°
          if (focus.getType() === 'Single') focusPosition.x -= focus.getRowBottom().getDepth() / 20;
          focusPosition.x -= focus.getDepthExtension() / 20;
          if (focus.isInstallationWall()) focusPosition.x -= focus.getInstallationWallDepth() / 20;
        } else if (focus.getRotation().y >= Math.PI * 0.75 && focus.getRotation().y < Math.PI * 1.25) {
          // 180°
          if (focus.getType() === 'Single') focusPosition.z += focus.getRowBottom().getDepth() / 20;
          focusPosition.z += focus.getDepthExtension() / 20;
          if (focus.isInstallationWall()) focusPosition.z += focus.getInstallationWallDepth() / 20;
        } else if (focus.getRotation().y >= Math.PI * 1.25 && focus.getRotation().y < Math.PI * 1.75) {
          // 270°
          if (focus.getType() === 'Single') focusPosition.x += focus.getRowBottom().getDepth() / 20;
          focusPosition.x += focus.getDepthExtension() / 20;
          if (focus.isInstallationWall()) focusPosition.x += focus.getInstallationWallDepth() / 20;
        }
      } else if (focus instanceof FreeItem) {
        try {
          focusPosition.z -= focus.getDeviceObject().model.depths[0] / 100;
        } catch (e) {}
      }
      // Top Camera for Plan / Export
      const screenshotTop = new BABYLON.ArcRotateCamera('ScreenshotTop', 0, 0, 10, focusPosition, scene);
      screenshotTop.mode = BABYLON.Camera.ORTHOGRAPHIC_CAMERA;
      screenshotTop.radius = cameraDistance;
      screenshotTop.alpha = -Math.PI / 2 + focus.getRotation().y;

      // Diagonal Camera for Export
      const screenshotDiagonal = new BABYLON.ArcRotateCamera('ScreenshotDiagonal', 0, 0, 10, focusPosition, scene);
      screenshotDiagonal.alpha = Math.PI + Math.PI / 4 + focus.getRotation().y;
      screenshotDiagonal.beta = Math.PI / 3;
      screenshotDiagonal.radius = cameraDistance;

      // Diagonal Back Camera for Export
      const screenshotDiagonalBack = new BABYLON.ArcRotateCamera('ScreenshotDiagonalBack', 0, 0, 10, focusPosition, scene);
      screenshotDiagonalBack.alpha = Math.PI / 4 + focus.getRotation().y;
      screenshotDiagonalBack.beta = Math.PI / 3;
      screenshotDiagonalBack.radius = cameraDistance;

      const response: ScreenshotData = {
        top: null,
        viewA: null,
        viewB: null
      };

      const blockWidth = focus.getWidth();

      const zoomMultiplier =
        blockWidth < 1000
          ? 0.5
          : blockWidth <= 3000
          ? 0.65
          : blockWidth <= 4200
          ? 0.75
          : blockWidth <= 5000
          ? 0.85
          : blockWidth <= 5800
          ? 0.9
          : blockWidth <= 6200
          ? 1
          : blockWidth <= 7000
          ? 1.15
          : blockWidth <= 8000
          ? 1.25
          : blockWidth <= 9000
          ? 1.375
          : 1.45;

      const diagonalMultiplier = blockWidth < 1000 ? 1 : blockWidth <= 4200 ? 1.3 : blockWidth <= 6200 ? 1.5 : 1.8;

      // QuallityMultiplier
      const qm = quallity;
      let cameraZoom = cameraDistance * zoomMultiplier + labelOffsetTop;

      const w = 722;
      const h = 360;

      const cameraTopHeight = (cameraZoom / w) * h;
      screenshotTop.orthoTop = cameraTopHeight / 2;
      screenshotTop.orthoBottom = -cameraTopHeight / 2;
      screenshotTop.orthoLeft = -cameraZoom / 2;
      screenshotTop.orthoRight = cameraZoom / 2;

      cameraZoom -= labelOffsetTop - labelOffsetDiagonal;

      screenshotDiagonal.radius = cameraZoom / diagonalMultiplier;
      screenshotDiagonalBack.radius = cameraZoom / diagonalMultiplier;

      // screenshotDiagonal.target.y += targetY;
      // screenshotDiagonal.alpha += alphaAddition;
      // screenshotDiagonal.beta -= betaAddition;

      // screenshotDiagonalBack.target.y += targetY;
      // screenshotDiagonalBack.alpha += alphaAddition;
      // screenshotDiagonalBack.beta -= betaAddition;

      engine.flushFramebuffer();

      response.top = await BABYLON.Tools.CreateScreenshotUsingRenderTargetAsync(engine, screenshotTop, { width: w * qm, height: h * qm }, 'image/png');
      scene.render();

      response.viewA = await BABYLON.Tools.CreateScreenshotUsingRenderTargetAsync(engine, screenshotDiagonal, { width: w * qm, height: h * qm }, 'image/png');
      scene.render();

      response.viewB = await BABYLON.Tools.CreateScreenshotUsingRenderTargetAsync(
        engine,
        screenshotDiagonalBack,
        { width: w * qm, height: h * qm },
        'image/png'
      );
      scene.render();

      screenshotTop.dispose();
      screenshotDiagonal.dispose();
      screenshotDiagonalBack.dispose();

      if (focus instanceof Block) {
        if (marked) {
          focus.getNode().getMark().setEnabled(true);
          focus.getNode().setShowGrab(true);
        }
      }

      if (success) success(response);
    };

    this.focusCamera = (center: Block) => {
      const freeA = camera.alpha;
      const freeB = camera.beta;
      const freeR = camera.radius;

      const topA = cameraTop.alpha;
      const topB = cameraTop.beta;
      const topR = cameraTop.radius;
      if (center) {
        camera.setTarget(center.getNode().position.clone());
        cameraTop.setTarget(center.getNode().position.clone());
      } else {
        camera.setTarget(BABYLON.Vector3.Zero());
        cameraTop.setTarget(BABYLON.Vector3.Zero());
      }
      camera.alpha = freeA;
      camera.beta = freeB;
      camera.radius = freeR;

      cameraTop.alpha = topA;
      cameraTop.beta = topB;
      cameraTop.radius = topR;
    };

    let draggedDevice: BlockItem | BlockGroup | FreeItem | RoomChild = null;
    if (!this.props.viewOnly) {
      this.onDragOver = (device, block, configuration) => {
        // console.log('onDragOver', device, block, configuration);

        if (device instanceof RoomChild) {
          if (device instanceof WallClipping) {
            this.setSelected(device);
            startingPoint = getGroundPosition();
            currentMesh = device.getNode();
            currentMeshType = 'wallmounted';
            currentMeshStartingPoint = startingPoint;

            draggedDevice = device;
          } else if (device instanceof InnerRoom) {
            this.setSelected(device);
            startingPoint = getGroundPosition();
            currentMesh = device.getNode();
            currentMeshType = 'innerroom';
            currentMeshStartingPoint = startingPoint;

            draggedDevice = device;
          } else {
            console.log('What is it?');
          }
        } else {
          // It's a Device my Friend!
          if (block) {
            if (block.canItemBeDropped(device, block.getRowTop())) {
              draggedDevice = block.getRowTop().addItem(device);
              if (
                block.isAutoHygiene() &&
                (draggedDevice instanceof BlockItem || draggedDevice instanceof BlockGroup) &&
                EquipmentHelper.canHaveHygiene(draggedDevice)
              ) {
                addHygiene(draggedDevice, block.getRowTop().getDepth());
              }
            } else if (block.canItemBeDropped(device, block.getRowBottom())) {
              draggedDevice = block.getRowBottom().addItem(device);
              if (
                block.isAutoHygiene() &&
                (draggedDevice instanceof BlockItem || draggedDevice instanceof BlockGroup) &&
                EquipmentHelper.canHaveHygiene(draggedDevice)
              ) {
                addHygiene(draggedDevice, block.getRowBottom().getDepth());
              }
            } else {
              draggedDevice = null;
            }
            if (draggedDevice) {
              this.setSelected(draggedDevice);
              startingPoint = getGroundPosition();
              currentMesh = draggedDevice.getNode();
              currentMeshType = 'device';
              currentMeshStartingPoint = startingPoint;
              startMoveInterval();
            }
          } else if (configuration) {
            try {
              let item: FreeItem = null;
              if (device.model.flexiChef) {
                item = new FreeItem(device, configuration);
              } else if (device.model.spaceCombi) {
                item = new FreeItem(device, configuration);
              } else {
                console.log("Don't know what it is :(");
              }

              if (item) {
                this.setSelected(draggedDevice);
                startingPoint = getGroundPosition();
                currentMesh = draggedDevice.getNode();
                currentMeshType = 'device';
                currentMeshStartingPoint = startingPoint;
                draggedDevice = item;
              }
            } catch (e) {
              console.error(e);
            }
          } else {
            console.log('Nothing, nothing nothiiiiiiiiiiiing!!!!!!!');
          }
        }
      };

      this.onDragOut = () => {
        if (
          draggedDevice &&
          draggedDevice instanceof BlockItem &&
          currentMesh &&
          currentMesh instanceof DeviceNode &&
          draggedDevice.getUniqueId() === currentMesh.getBlockItem().getUniqueId()
        ) {
          clearInterval(moveInterval);
          startingPoint = null;
          currentMesh = null;
          currentMeshType = null;
          currentMeshStartingPoint = null;
          draggedDevice.getBlockRow().getParent().getNode().setDropMarkEnabled(false);
          draggedDevice.delete();
        } else if (
          draggedDevice &&
          draggedDevice instanceof FreeItem &&
          currentMesh &&
          currentMesh instanceof DeviceNode &&
          draggedDevice.getNode().uniqueId === currentMesh.uniqueId
        ) {
          startingPoint = null;
          currentMesh = null;
          currentMeshType = null;
          currentMeshStartingPoint = null;
          draggedDevice.delete();
        } else if (draggedDevice && draggedDevice instanceof RoomChild && currentMesh && draggedDevice.getNode().uniqueId === currentMesh.uniqueId) {
          startingPoint = null;
          currentMesh = null;
          currentMeshType = null;
          currentMeshStartingPoint = null;
          draggedDevice.delete();
        }
      };
    }

    this.setMergeMode = (value: boolean) => {
      // console.log('mergeMode', value);
      if (this._substructureMode) {
        this._mergeMode = value;
        if (value && this._selected && (this._selected instanceof BlockItem || this._selected instanceof BlockGroup)) {
          updateMergeMode();
        } else {
          this._mergeMode = false;
          if (this._selected && (this._selected instanceof BlockItem || this._selected instanceof BlockGroup)) {
            const parent = this._selected.getParent();
            if (parent instanceof BlockRow) {
              for (let i = 0; i < parent.getItems().length; i++) {
                const item = parent.getItems()[i];
                item.getNode().set(DNPT.MarkFull, false);
              }
            }
          }
        }
      }
    };

    const updateMergeMode = () => {
      if (this._selected && (this._selected instanceof BlockItem || this._selected instanceof BlockGroup)) {
        const parent = this._selected.getParent();
        let leftDevice: Device = null;
        let rightDevice: Device = null;
        if (this._selected instanceof BlockItem) {
          leftDevice = this._selected.getDeviceObject();
          rightDevice = this._selected.getDeviceObject();
        } else {
          leftDevice = this._selected.getItems()[0].getDeviceObject();
          rightDevice = this._selected.getItems()[this._selected.getItems().length - 1].getDeviceObject();
        }
        if (parent instanceof BlockRow) {
          const isBottom = parent.getType() === 'Bottom';
          for (let i = 0; i < parent.getItems().length; i++) {
            const item = parent.getItems()[i];
            item.getNode().set(DNPT.MarkFull, false);
          }
          for (let i = 0; i < parent.getItems().length; i++) {
            const item = parent.getItems()[i];
            if (item.getUniqueId() === this._selected.getUniqueId()) {
              if (i > 0 && ((isBottom && leftDevice.dependency.expandableLeft) || (!isBottom && rightDevice.dependency.expandableRight))) {
                const prev = parent.getItems()[i - 1];
                if (prev instanceof BlockItem) {
                  if (this._selected instanceof BlockGroup && this._selected.numberOfFunctionItem() >= 2 && Configuration.isFunctionItem(prev)) continue;
                  if (
                    ((isBottom && prev.getDeviceObject().dependency.expandableRight) || (!isBottom && prev.getDeviceObject().dependency.expandableLeft)) &&
                    this._selected.getWidth() + prev.getWidth() <= BlockGroup._maxGroupSize
                  ) {
                    prev.getNode().set(DNPT.MarkFull, true);
                  }
                }
              }
              if (
                i <= parent.getItems().length - 2 &&
                ((isBottom && rightDevice.dependency.expandableRight) || (!isBottom && leftDevice.dependency.expandableLeft))
              ) {
                const next = parent.getItems()[i + 1];
                if (next instanceof BlockItem) {
                  if (this._selected instanceof BlockGroup && this._selected.numberOfFunctionItem() >= 2 && Configuration.isFunctionItem(next)) continue;
                  if (
                    ((isBottom && next.getDeviceObject().dependency.expandableLeft) || (!isBottom && next.getDeviceObject().dependency.expandableRight)) &&
                    this._selected.getWidth() + next.getWidth() <= BlockGroup._maxGroupSize
                  ) {
                    next.getNode().set(DNPT.MarkFull, true);
                  }
                }
              }

              break;
            }
          }
        }
      }
    };

    scene.onDispose = () => {
      if (!this.props.viewOnly) {
        canvas.removeEventListener('pointerdown', onPointerDown);
        canvas.removeEventListener('pointerup', onPointerUp);
        canvas.removeEventListener('pointermove', onPointerMove);
        canvas.removeEventListener('contextmenu', onContextMenu);
      }
    };
    //#endregion

    // Add HDR Texture
    const hdrTexture = BABYLON.CubeTexture.CreateFromPrefilteredData('texture/hdr/environment.env', scene);
    hdrTexture.gammaSpace = false;
    hdrTexture.level = 1.5;
    hdrTexture.rotationY = 1;
    scene.environmentTexture = hdrTexture;

    //#region Debug Views
    window['activeScene'] = this;
    canvas.addEventListener('keyup', (e: KeyboardEvent) => {
      switch (e.key) {
        case 's':
          scene.shadowsEnabled = !scene.shadowsEnabled;
          break;
        case 'r':
          const groundMaterial = ground.material as BABYLON.StandardMaterial;
          if (groundMaterial.reflectionTexture === groundMirrorTexture) {
            groundMaterial.reflectionTexture = groundMirrorTextureEmpty;
          } else {
            groundMaterial.reflectionTexture = groundMirrorTexture;
          }
          break;
        case 'g':
          if ((ground.material as BABYLON.StandardMaterial).diffuseTexture) {
            (ground.material as BABYLON.StandardMaterial).diffuseTexture = undefined;
          } else {
            (ground.material as BABYLON.StandardMaterial).diffuseTexture = groundGrid;
          }
          break;
        case '+':
          scene.debugLayer.show();
          break;
        case 'o':
          this.zoomIn();
          break;
        case 'l':
          this.zoomOut();
          break;
        case 'k':
          this.zoomAuto();
          break;
        case 'e':
          const notLike = (name: string, mesh: BABYLON.Mesh) => {
            return !mesh.name.startsWith(name) && !mesh.parent.name.startsWith(name);
          };
          console.log('Start Download');
          if (window.confirm('Bitte stellen Sie sicher das die Konfiguration gespeichert wurde, die Seite wird im anschluss an den Download neu geladen.')) {
            const zip = new JSZip();
            let entry = 0;
            scene.getNodes().forEach(n => {
              if (n.id.startsWith('block')) {
                const meshes: BABYLON.Mesh[] = [];
                (n as BlockNode).scaling = new BABYLON.Vector3(10, 10, 10);
                BasicUtils.computeAllWorldMatrix(n as BlockNode);
                n.getChildren(undefined, false).forEach(c => {
                  if (c instanceof BABYLON.Mesh) {
                    if (c.parent.name === 'output') {
                      meshes.push(c);
                    }
                    // if (notLike('label', c) && notLike('grab', c) && notLike('mark', c) && notLike('dropMark', c)) {
                    //   meshes.push(c);
                    // }
                  }
                });
                const stl = STLExport.CreateSTL(meshes, false, 'export', false, false, false);
                zip.file(`${++entry}_${(n as BlockNode).getBlock().getBlockType()}.stl`, stl);
              }
            });
            console.log('Download for', entry, 'Blocks');
            if (entry > 0) {
              zip.generateAsync({ type: 'blob' }).then(function (content) {
                saveAs(content, '3d_export.zip');
                window.location.reload();
              });
            }
            // STLExport.CreateSTL(meshes, true, 'export', false, false, false);
            // window.location.reload();
          }
          break;
      }
    });
    //#endregion

    return sceneMountReturn;
  };

  render() {
    return (
      <div className="Scene">
        <BabylonScene
          onSceneMount={this.onSceneMount}
          engineOptions={{ preserveDrawingBuffer: !isIOS(), antialias: !isIOS(), stencil: true }}
          sceneOptions={{ useClonedMeshMap: true }}
          ref={this._babylonScene}
        />
      </div>
    );
  }
}
