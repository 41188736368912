import { FC, Fragment, useContext, useEffect, useMemo } from 'react';

// Components
import { ContextMenu, EquipmentSwitch } from 'page/Editor/Menu/Shared';
import BlockItem from 'page/Editor/configuration/BlockItem';
import { EquipmentHelper } from 'page/Editor/configuration/Equipment';

// Context
import { EditorContext, LangContext } from 'context';

// Language
import Localizations from 'languages';

// Styles:
import { colors } from 'styles/theme';

// Types
import { Device, Subtype } from 'types';

// Utilities
import { Button, Icon, Selectbox } from 'atoms';
import { CutOutInputs } from 'page/Editor/Menu/Shared/CutOutInputs';
import Vector3 from 'page/Editor/configuration/Vector3';
import { isSubtype } from 'utils';
import { isDeviceWorktable } from 'utils/isDeviceWorktable';
import { useHandleSelection } from './hooks';

// ===================================================================
interface Props {}

// ===================================================================

export const MasterlineUpperStructure: FC<Props> = () => {
  const { lang, langId } = useContext(LangContext);

  const { scene, selection, setMasterlineView, setSelection } = useContext(EditorContext);

  const {
    availableSubtypes,
    availableSockets,
    availableDevices,
    mergeMode,
    selectionType,
    usedDevices,
    setUsedDevices,
    setSelectedUpperPowerSocket,
    setSelectedUpperPowerSocket_Second,
    selectedUpperPowerSocket,
    selectedUpperPowerSocket_Second
  } = useHandleSelection();

  useEffect(() => {
    if (scene) {
      scene.setUpperStructureMode(true);
      if (scene.getSelected()) {
        setSelection(scene.getSelected());
      }
    }

    return () => {
      if (scene) {
        scene.setUpperStructureMode(false);
      }
      setSelection(null);
    };
  }, []);

  const canRotateUpperStructure = useMemo(() => {
    if (selection instanceof BlockItem) {
      return selection.getDeviceObject().style?.includes('rotatableUpperStructure');
    }
    return false;
  }, [selection]);

  const shouldShowCutOut = selection instanceof BlockItem && isDeviceWorktable(selection.getDeviceObject());

  return (
    <Fragment>
      <ContextMenu
        mode="back"
        onClick={() => {
          setMasterlineView('home');
          setSelection(null);
          scene.setSelected(null);
        }}
      />

      {selectionType && !(selectionType === 'BlockItem' && mergeMode) && availableSubtypes.length > 1 && (
        <div
          className="bold uppercase text-xs mb-1"
          style={{
            color: colors['greyText']
          }}
        >
          {Localizations['specialEquipment'][lang]}
        </div>
      )}

      {!selection && <div className="mt-4 text-sm bold">{Localizations['pickADevice'][lang]}</div>}
      {!canRotateUpperStructure && selection && availableSubtypes.length < 1 && !shouldShowCutOut && (
        <div className="mt-4 text-sm bold">{Localizations['noUpperDevices'][lang]}</div>
      )}
      {canRotateUpperStructure && selection instanceof BlockItem && (
        <Button
          className="flex align-center justify-center"
          btnType="first"
          fontSize=".85rem"
          margin="0rem 0 1rem 0"
          padding=".65rem 2rem"
          onClick={() => {
            selection.setUpperRotation(new Vector3(0, selection.getUpperRotation().y + Math.PI / 2, 0));
          }}
        >
          {Localizations['rotation'][lang]}
          <Icon type="rotate" size="1rem" style={{ margin: '0 1rem' }} />
        </Button>
      )}
      {selection && selection instanceof BlockItem && (
        <div className="Custom-Scroll" style={{ maxHeight: 'calc(100% - 80px)' }}>
          {availableSubtypes.includes(Subtype.UpperDrawer) && (
            <EquipmentSwitch
              disabled={!EquipmentHelper.canHaveUpperDrawer(selection)}
              description={
                availableDevices.find(d => isSubtype(d.subtype, ['UpperDrawer']))
                  ? availableDevices.find(d => d.subtype, ['UpperDrawer']).translations[langId].name
                  : ''
              }
              status={usedDevices.UpperDrawer}
              label={Localizations['upperDrawer'][lang]}
              toggleStatus={() => {
                if (!usedDevices.UpperDrawer) {
                  EquipmentHelper.setUpperDrawer(
                    selection,
                    availableDevices.find(d => isSubtype(d.subtype, ['UpperDrawer']))
                  );
                } else {
                  EquipmentHelper.setUpperDrawer(selection, null);
                }
                setUsedDevices({ ...usedDevices, UpperDrawer: !usedDevices.UpperDrawer });
              }}
              type="first"
            />
          )}
          {availableSubtypes.includes(Subtype.UpperPowerSocket) && (
            <Selectbox
              label={'1. ' + Localizations['upperPowerSocket'][lang]}
              disabled={!EquipmentHelper.canHaveUpperPowerSocket(selection)}
              options={[Localizations['none'][lang], ...availableSockets]}
              state={selectedUpperPowerSocket}
              setState={(value: string) => {
                EquipmentHelper.setUpperPowerSocket(
                  selection,
                  availableDevices.find((s: Device) => s.translations[langId].name === value)
                );
                setSelectedUpperPowerSocket(value);
              }}
            />
          )}
          {availableSubtypes.includes(Subtype.UpperPowerSocket) && (
            <div className="mt-1">
              <Selectbox
                label={'2. ' + Localizations['upperPowerSocket'][lang]}
                disabled={!EquipmentHelper.canHaveUpperPowerSocket(selection, 1)}
                options={[Localizations['none'][lang], ...availableSockets]}
                state={selectedUpperPowerSocket_Second}
                setState={(value: string) => {
                  EquipmentHelper.setUpperPowerSocket(
                    selection,
                    availableDevices.find((s: Device) => s?.translations[langId].name === value),
                    1
                  );
                  setSelectedUpperPowerSocket_Second(value);
                }}
              />
            </div>
          )}
          {availableSubtypes.includes(Subtype.MixingFaucet) && (
            <EquipmentSwitch
              disabled={!EquipmentHelper.canHaveMixingFaucet(selection)}
              description={
                availableDevices.find(d => isSubtype(d.subtype, ['MixingFaucet']))
                  ? availableDevices.find(d => isSubtype(d.subtype, ['MixingFaucet'])).translations[langId].name
                  : ''
              }
              status={usedDevices.MixingFaucet}
              label={Localizations['mixingFaucet'][lang]}
              toggleStatus={() => {
                if (!usedDevices.MixingFaucet) {
                  EquipmentHelper.setMixingFaucet(
                    selection,

                    availableDevices.find(d => isSubtype(d.subtype, ['MixingFaucet']))
                  );
                } else {
                  EquipmentHelper.setMixingFaucet(selection, null);
                }
                setUsedDevices({ ...usedDevices, MixingFaucet: !usedDevices.MixingFaucet });
              }}
              type="first"
            />
          )}
          {availableSubtypes.includes(Subtype.ColdWaterInlet) && (
            <EquipmentSwitch
              disabled={!EquipmentHelper.canHaveColdWaterInlet(selection)}
              description={
                availableDevices.find(d => isSubtype(d.subtype, ['ColdWaterInlet']))
                  ? availableDevices.find(d => isSubtype(d.subtype, ['ColdWaterInlet'])).translations[langId].name
                  : ''
              }
              status={usedDevices.ColdWaterInlet}
              label={Localizations['coldWaterInlet'][lang]}
              toggleStatus={() => {
                if (!usedDevices.ColdWaterInlet) {
                  EquipmentHelper.setColdWaterInlet(
                    selection,

                    availableDevices.find(d => isSubtype(d.subtype, ['ColdWaterInlet']))
                  );
                } else {
                  EquipmentHelper.setColdWaterInlet(selection, null);
                }
                setUsedDevices({ ...usedDevices, ColdWaterInlet: !usedDevices.ColdWaterInlet });
              }}
              type="first"
            />
          )}
          {availableSubtypes.includes(Subtype.DoubleSidedOperation) && (
            <EquipmentSwitch
              disabled={!EquipmentHelper.canHaveDoubleSidedOperation(selection)}
              description={
                availableDevices.find(d => isSubtype(d.subtype, ['DoubleSidedOperation']))
                  ? availableDevices.find(d => isSubtype(d.subtype, ['DoubleSidedOperation'])).translations[langId].name
                  : ''
              }
              status={usedDevices.DoubleSidedOperation}
              label={Localizations['doubleSidedOperation'][lang]}
              toggleStatus={() => {
                if (!usedDevices.DoubleSidedOperation) {
                  EquipmentHelper.setDoubleSidedOperation(
                    selection,

                    availableDevices.find(d => isSubtype(d.subtype, ['DoubleSidedOperation']))
                  );
                } else {
                  EquipmentHelper.setDoubleSidedOperation(selection, null);
                }
                setUsedDevices({ ...usedDevices, DoubleSidedOperation: !usedDevices.DoubleSidedOperation });
              }}
              type="first"
            />
          )}
        </div>
      )}
      {shouldShowCutOut && <CutOutInputs />}
    </Fragment>
  );
};
